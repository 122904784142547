export const FORMULA_TYPE__UNKNOWN = 'unknown';
export const FORMULA_TYPE__LITERAL = 'literal';
export const FORMULA_TYPE__GENERIC = 'generic';
export const FORMULA_TYPE__ANSWER_VALUE = 'answerValue';
export const FORMULA_TYPE__ANSWER_NUMERIC_VALUE = 'answerNumericValue';
export const FORMULA_TYPE__VARIABLE_VALUE = 'variableValue';
export const FORMULA_TYPE__SUM_NUMERIC_VALUES = 'sumNumericValues';
export const FORMULA_TYPE__AVERAGE_NUMERIC_VALUE = 'averageNumericValue';
export const FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE =
  'firstNonEmptyAnswerValue';
export const FORMULA_TYPE__CONCATENATE_VALUES = 'concatenateValues';
export const FORMULA_TYPE__ANSWERS_SHEET_PROPERTY = 'answersSheetProperty';
export const FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS = 'questionsWhereAnswerIs';
export const FORMULA_TYPE__CONTEXT_PROPERTY = 'contextProperty';
export const FORMULA_TYPE__REDUCE = 'reduce';
export const FORMULA_TYPE__UNARY = 'unary';
export const FORMULA_TYPE__BINARY = 'binary';
export const FORMULA_TYPE__ELEMENT_ID = 'elementId';
export const FORMULA_TYPE__TO_VALUE_SET = 'toValueSet';
// TODO: Implement this one, it will be fun :)
// export const FORMULA_TYPE__TO_LOOKUP_TABLE = 'toLookupTable';

export const FORMULA_TYPE__ANSWER_IS_ONE_OF = 'answerIsOneOf';
export const FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF =
  'answerIsMissingOrOneOf';
export const FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF = 'answerIsNotOneOf';
export const FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF =
  'answerIsMissingOrNotOneOf';
export const FORMULA_TYPE__ANSWER_EXISTS = 'answerExists';
export const FORMULA_TYPE__ANSWER_DOES_NOT_EXIST = 'answerDoesNotExist';
export const FORMULA_TYPE__VARIABLE_IS_ONE_OF = 'variableIsOneOf';
export const FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF = 'variableIsNotOneOf';
export const FORMULA_TYPE__ALWAYS_FALSE = 'alwaysFalse';
export const FORMULA_TYPE__ALWAYS_TRUE = 'alwaysTrue';

export const FORMULA_OPPOSITES = {
  [FORMULA_TYPE__ANSWER_IS_ONE_OF]: FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF,
  [FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF]: FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  [FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF]: FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF,
  [FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF]: FORMULA_TYPE__ANSWER_IS_ONE_OF,
  [FORMULA_TYPE__ANSWER_EXISTS]: FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  [FORMULA_TYPE__ANSWER_DOES_NOT_EXIST]: FORMULA_TYPE__ANSWER_EXISTS,
  [FORMULA_TYPE__VARIABLE_IS_ONE_OF]: FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF,
  [FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF]: FORMULA_TYPE__VARIABLE_IS_ONE_OF,
  [FORMULA_TYPE__ALWAYS_FALSE]: FORMULA_TYPE__ALWAYS_TRUE,
  [FORMULA_TYPE__ALWAYS_TRUE]: FORMULA_TYPE__ALWAYS_FALSE,
};

// binary
export const FORMULA_TYPE__EQUALS = 'equals';

export const FORMULA_TYPES = [
  FORMULA_TYPE__UNKNOWN,
  FORMULA_TYPE__LITERAL,
  FORMULA_TYPE__GENERIC,
  FORMULA_TYPE__ANSWER_VALUE,
  FORMULA_TYPE__ANSWER_NUMERIC_VALUE,
  FORMULA_TYPE__VARIABLE_VALUE,
  FORMULA_TYPE__ANSWER_IS_ONE_OF,
  FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  FORMULA_TYPE__ANSWER_EXISTS,
  FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  FORMULA_TYPE__VARIABLE_IS_ONE_OF,
  FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF,
  FORMULA_TYPE__ALWAYS_FALSE,
  FORMULA_TYPE__ALWAYS_TRUE,
  FORMULA_TYPE__SUM_NUMERIC_VALUES,
  FORMULA_TYPE__AVERAGE_NUMERIC_VALUE,
  FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE,
  FORMULA_TYPE__CONCATENATE_VALUES,
  FORMULA_TYPE__ANSWERS_SHEET_PROPERTY,
  FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS,
  FORMULA_TYPE__CONTEXT_PROPERTY,
  FORMULA_TYPE__REDUCE,
  FORMULA_TYPE__UNARY,
  FORMULA_TYPE__BINARY,
  FORMULA_TYPE__ELEMENT_ID,
  FORMULA_TYPE__TO_VALUE_SET,
];

export const FORMULA_TYPE_OPTIONS = [
  {
    value: FORMULA_TYPE__ANSWER_EXISTS,
    label: 'Answer exists',
  },
  {
    value: FORMULA_TYPE__ANSWER_IS_ONE_OF,
    label: 'Answer is one of',
  },
  {
    value: FORMULA_TYPE__ANSWER_VALUE,
    label: 'Answer value',
  },
  {
    value: FORMULA_TYPE__ANSWER_NUMERIC_VALUE,
    label: 'Answer numeric value',
  },
  {
    value: FORMULA_TYPE__LITERAL,
    label: 'Literal',
  },
  // { value: FORMULA_TYPE__GENERIC, label: 'Generic formula' },
  {
    value: FORMULA_TYPE__SUM_NUMERIC_VALUES,
    label: 'Sum values',
  },
  {
    value: FORMULA_TYPE__AVERAGE_NUMERIC_VALUE,
    label: 'Average numeric value',
  },
  {
    value: FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE,
    label: 'First non-empty value',
  },
  {
    value: FORMULA_TYPE__CONCATENATE_VALUES,
    label: 'Concatenate values',
  },
  {
    value: FORMULA_TYPE__ANSWERS_SHEET_PROPERTY,
    label: 'Property of answers sheet',
  },
  {
    value: FORMULA_TYPE__CONTEXT_PROPERTY,
    label: 'Property of current context',
  },
  // {
  //   value: FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS,
  //   label: 'Questions where answer satisfies condition',
  // },
  {
    value: FORMULA_TYPE__REDUCE,
    label: 'Combine other formulas',
  },
  {
    value: FORMULA_TYPE__UNARY,
    label: 'Unary operator',
  },
  {
    value: FORMULA_TYPE__BINARY,
    label: 'Binary operator',
  },
  {
    value: FORMULA_TYPE__VARIABLE_VALUE,
    label: 'Variable value',
  },
  {
    value: FORMULA_TYPE__VARIABLE_IS_ONE_OF,
    label: 'Variable is one of',
  },
  {
    value: FORMULA_TYPE__ELEMENT_ID,
    label: 'Current collection element identifier',
  },
  {
    value: FORMULA_TYPE__TO_VALUE_SET,
    label: 'Convert to value set',
  },
];

export const FORMULA_OPERATOR__SUM = 'SUM';
export const FORMULA_OPERATOR__PROD = 'PROD';
export const FORMULA_OPERATOR__OR = 'OR';
export const FORMULA_OPERATOR__AND = 'AND';
export const FORMULA_OPERATOR__ANY = 'ANY';
export const FORMULA_OPERATOR__ALL = 'ALL';
export const FORMULA_OPERATOR__IF_ERROR = 'IF_ERROR';
export const FORMULA_OPERATOR__CONCAT = 'CONCAT';
export const FORMULA_OPERATOR__WHITESPACE = 'WHITESPACE';
export const FORMULA_OPERATOR__NOT = 'NOT';
export const FORMULA_OPERATOR__LOOKUP = 'LOOKUP';
export const FORMULA_OPERATOR__SUB = 'SUB';
export const FORMULA_OPERATOR__DIV = 'DIV';
export const FORMULA_OPERATOR__MIN = 'MIN';
export const FORMULA_OPERATOR__MAX = 'MAX';
export const FORMULA_OPERATOR__MINUS = 'MINUS';
export const FORMULA_OPERATOR__EQUALS = 'EQUALS';
export const FORMULA_OPERATOR__NOT_EQUALS = 'NOT_EQUALS';
export const FORMULA_OPERATOR__LESS_THAN = 'LESS_THAN';
export const FORMULA_OPERATOR__LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL';
export const FORMULA_OPERATOR__GREATER_THAN = 'GREATER_THAN';
export const FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL';

// ASSOCIATIVE

export const FORMULA_ASSOCIATIVE_OPERATORS = [
  FORMULA_OPERATOR__SUM,
  FORMULA_OPERATOR__PROD,
  FORMULA_OPERATOR__OR,
  FORMULA_OPERATOR__AND,
  FORMULA_OPERATOR__ANY,
  FORMULA_OPERATOR__ALL,
  FORMULA_OPERATOR__CONCAT,
  FORMULA_OPERATOR__WHITESPACE,
  FORMULA_OPERATOR__MIN,
  FORMULA_OPERATOR__MAX,
  FORMULA_OPERATOR__EQUALS,
  FORMULA_OPERATOR__LESS_THAN,
  FORMULA_OPERATOR__LESS_THAN_OR_EQUAL,
  FORMULA_OPERATOR__GREATER_THAN,
  FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL,
];

export const FORMULA_ASSOCIATIVE_OPERATOR_OPTIONS = [
  {
    value: FORMULA_OPERATOR__SUM,
    label: 'Sum',
  },
  {
    value: FORMULA_OPERATOR__PROD,
    label: 'Product',
  },
  {
    value: FORMULA_OPERATOR__OR,
    label: 'OR',
  },
  {
    value: FORMULA_OPERATOR__AND,
    label: 'AND',
  },
  {
    value: FORMULA_OPERATOR__ANY,
    label: 'Any ~ TRUE',
  },
  {
    value: FORMULA_OPERATOR__ALL,
    label: 'All ~ TRUE',
  },
  {
    value: FORMULA_OPERATOR__CONCAT,
    label: 'Concatenate',
  },
  {
    value: FORMULA_OPERATOR__WHITESPACE,
    label: 'Join using white space',
  },
  {
    value: FORMULA_OPERATOR__MIN,
    label: 'Minimum',
  },
  {
    value: FORMULA_OPERATOR__MAX,
    label: 'Maximum',
  },
];

// UNARY

export const FORMULA_UNARY_OPERATORS = [
  FORMULA_OPERATOR__NOT,
  FORMULA_OPERATOR__MINUS,
];

export const FORMULA_UNARY_OPERATOR_OPTIONS = [
  {
    value: FORMULA_OPERATOR__NOT,
    label: 'Not',
  },
  {
    value: FORMULA_OPERATOR__MINUS,
    label: 'Negate (minus)',
  },
];

// BINARY

export const FORMULA_BINARY_OPERATORS = [
  FORMULA_OPERATOR__SUM,
  FORMULA_OPERATOR__SUB,
  FORMULA_OPERATOR__PROD,
  FORMULA_OPERATOR__DIV,
  FORMULA_OPERATOR__OR,
  FORMULA_OPERATOR__AND,
  FORMULA_OPERATOR__IF_ERROR,
  FORMULA_OPERATOR__CONCAT,
  FORMULA_OPERATOR__WHITESPACE,
  FORMULA_OPERATOR__LOOKUP,
  FORMULA_OPERATOR__MIN,
  FORMULA_OPERATOR__MAX,
];

export const FORMULA_BINARY_OPERATOR_OPTIONS = [
  {
    value: FORMULA_OPERATOR__SUM,
    label: '(+) Add',
  },
  {
    value: FORMULA_OPERATOR__SUB,
    label: '(-) Subtract',
  },
  {
    value: FORMULA_OPERATOR__PROD,
    label: '(*) Multiply',
  },
  {
    value: FORMULA_OPERATOR__DIV,
    label: '(/) Divide',
  },
  {
    value: FORMULA_OPERATOR__OR,
    label: 'OR = FIRST or SECOND if FIRST is FALSY',
  },
  {
    value: FORMULA_OPERATOR__AND,
    label: 'AND = FIRST or SECOND if FIRST is TRUTHY',
  },
  {
    value: FORMULA_OPERATOR__IF_ERROR,
    label: 'If #ERR THEN ... - FIRST or SECOND if FIRST results in #ERR',
  },
  {
    value: FORMULA_OPERATOR__CONCAT,
    label: 'Concatenate strings',
  },
  {
    value: FORMULA_OPERATOR__LOOKUP,
    label: 'x[y] Find value in a lookup table',
  },
  {
    value: FORMULA_OPERATOR__MIN,
    label: '(min) Minimum',
  },
  {
    value: FORMULA_OPERATOR__MAX,
    label: '(max) Maximum',
  },
  {
    value: FORMULA_OPERATOR__EQUALS,
    label: '(=) Equals',
  },
  {
    value: FORMULA_OPERATOR__NOT_EQUALS,
    label: '(≠) Not equals',
  },
  {
    value: FORMULA_OPERATOR__LESS_THAN,
    label: '(<) Less than',
  },
  {
    value: FORMULA_OPERATOR__LESS_THAN_OR_EQUAL,
    label: '(≤) Less than or equal',
  },
  {
    value: FORMULA_OPERATOR__GREATER_THAN,
    label: '(>) Greater than',
  },
  {
    value: FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL,
    label: '(≥) Greater than or equal',
  },
];

export const FORMULA_CONTEXT_PROPERTY__CURRENT_USER_ID = 'currentUserId';
export const FORMULA_CONTEXT_PROPERTY__CURRENT_USER_NAME = 'currentUserName';
export const FORMULA_CONTEXT_PROPERTY__CURRENT_USER_JOB_TITLE =
  'currentUserJobTitle';
export const FORMULA_CONTEXT_PROPERTY__CURRENT_DATE_TIME = 'currentDateTime';
export const FORMULA_CONTEXT_PROPERTY__CURRENT_DATE = 'currentDate';

export const FORMULA_CONTEXT_PROPERTY_OPTIONS = [
  {
    value: FORMULA_CONTEXT_PROPERTY__CURRENT_USER_ID,
    label: 'Current user id',
  },
  {
    value: FORMULA_CONTEXT_PROPERTY__CURRENT_USER_NAME,
    label: 'Current user name',
  },
  {
    value: FORMULA_CONTEXT_PROPERTY__CURRENT_USER_JOB_TITLE,
    label: 'Current user job title',
  },
  {
    value: FORMULA_CONTEXT_PROPERTY__CURRENT_DATE_TIME,
    label: 'Current date and time',
  },
  {
    value: FORMULA_CONTEXT_PROPERTY__CURRENT_DATE,
    label: 'Current date',
  },
];
