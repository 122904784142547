import styled, {
  createGlobalStyle,
} from 'styled-components/macro';
import React, {
  useRef,
  useEffect,
} from 'react';
import SelectAntd from 'antd/es/select';
import 'antd/es/select/style/css';
import {
  theme,
} from '../utilsClient/cssHelpers';

const Component = styled(SelectAntd)`
  &&& {
    &,
    .ant-select-arrow {
      color: ${theme('color.onSurface')};
    }

    .ant-select-selector {
      background-color: ${theme('color.surface')};
      border-color: ${theme('color.border')};
    }

    &.ant-select-disabled {
      .ant-select-selector {
        background-color: ${theme('color.disabled')};
        border-color: ${theme('color.border')} !important;
      }

      .ant-select-selector,
      .ant-select-arrow {
        color: ${theme('color.onDisabled')};
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown {
    &&& {
      background-color: ${theme('color.surface')};
    }
  }

  .ant-select-item-option {
    &&& {
      color: ${theme('color.onSurface')};

      &-selected {
        color: ${theme('color.onSurface')};
      }

      &-selected,
      &-active,
      &:hover:not(.ant-select-item-option-disabled) {
        background-color: ${theme('color.hover')};
      }
    }
  }

  .ant-empty {
    && {
      color: ${theme('color.onSurface')};
    }
  }
`;

const StyledSelect = ({
  // eslint-disable-next-line react/prop-types
  showSearch,
  ...rest
}) => {
  const ref = useRef(null);

  useEffect(() => {
    // Ant Design select comes with autocomplete="off" for the search input
    // but it doesn't seem to work in Chrome
    // adding a dummy value to autocomplete attr seems to fix the issue
    // as per https://stackoverflow.com/a/38961567
    if (showSearch) {
      const searchInput = ref.current.querySelector(
        '.ant-select-selection-search-input',
      );

      if (searchInput) {
        searchInput.setAttribute('autocomplete', 'nope');
      }
    }
  }, [
    showSearch,
  ]);

  return (
    <div ref={ref}>
      <GlobalStyle />
      <Component
        showSearch={showSearch}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </div>
  );
};

export default SelectAntd;

export {
  StyledSelect,
};
