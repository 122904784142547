/*  */
import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${props => props.min};
  padding: ${props => props.hasPadding && `${theme(`space.${props.space}`)(props)}`};

  > * {
    margin-top: ${props => `${theme(`space.${props.space}`)(props)}`};
    margin-bottom: ${props => `${theme(`space.${props.space}`)(props)}`};

    ${props => props.isStretched && 'flex-grow: 1'};
  }

  ${props => `
    > :first-child:not(${props.centeredSelector}) {
      margin-top: 0;
    }

    > :last-child:not(${props.centeredSelector}) {
      margin-bottom: 0;
    }

    > ${props.centeredSelector} {
      margin-top: auto;
      margin-bottom: auto;

      ${props.isScrollable ? 'overflow-y: auto' : ''}
    }
  `}
`;

const Cover = ({
  children,
  centeredSelector,
  isStretched,
  isScrollable,
  hasPadding,
  space,
  min,
}) => {
  return (
    <Component
      centeredSelector={centeredSelector}
      isStretched={isStretched}
      isScrollable={isScrollable}
      hasPadding={hasPadding}
      space={space}
      min={min}
    >
      {children}
    </Component>
  );
};

Cover.propTypes = {
  children: PropTypes.node.isRequired,
  centeredSelector: PropTypes.string,
  isStretched: PropTypes.bool,
  isScrollable: PropTypes.bool,
  hasPadding: PropTypes.bool,
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  min: PropTypes.string,
};

Cover.defaultProps = {
  centeredSelector: 'div',
  isStretched: false,
  isScrollable: false,
  hasPadding: false,
  space: 3,
  min: '100%',
};

export default Cover;
