import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import indexOf from 'lodash/indexOf';
import {
  setAtKey,
  delAtKey,
  pullAtKey,
  pushAtKey,
} from '../../utilsClient/immutable';
import createGetAtKey from '../../utilsClient/createGetAtKey';
import {
  ACTION_SET_VALUE,
  ACTION_ENTER_FORM_VALUES,
  ACTION_INSERT_ELEMENT,
  ACTION_REMOVE_ELEMENT,
  ACTION_MOVE_ELEMENT_UP,
  ACTION_MOVE_ELEMENT_DOWN,
  ACTION_INITIALIZE,
  ACTION_CLEAR,
  ACTION_CLEAR_ALL,
  ACTION_SYNC_FORM_VALUES,
  ACTION_REPLACE_FORM_ERRORS,
  ACTION_REPLACE_FORM_VALUES,
} from './actions';

const createMask = (value) => {
  if (isPlainObject(value)) {
    return mapValues(value, createMask);
  }
  return true;
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_SET_VALUE: {
      if (action.meta) {
        const {
          name,
          key,
          field,
        } = action.meta;
        const current = state[name] || {};
        const fullKey = `${key}.${field}`;
        return {
          ...state,
          [name]: {
            ...current,
            values: setAtKey(current.values, fullKey, action.payload),
            touched: setAtKey(current.touched, fullKey, true),
            errors: delAtKey(current.errors, fullKey, {
              cascade: true,
            }),
          },
        };
      }
      return state;
    }
    case ACTION_ENTER_FORM_VALUES: {
      if (action.meta) {
        const {
          name,
          key = '',
        } = action.meta;
        const getAtKey = createGetAtKey(key);
        const current = state[name] || {};
        const values = {
          ...getAtKey(current.values),
        };
        const touched = {
          ...getAtKey(current.touched),
        };
        const errors = {
          ...getAtKey(current.errors),
        };
        forEach(action.payload, (value, field) => {
          values[field] = value;
          touched[field] = createMask(value);
          delete errors[field];
        });
        return {
          ...state,
          [name]: {
            ...current,
            values: setAtKey(current.values, key, values),
            touched: setAtKey(current.touched, key, touched),
            errors: isEmpty(errors)
              ? delAtKey(current.errors, key, {
                cascade: true,
              })
              : setAtKey(current.errors, key, errors),
          },
        };
      }
      return state;
    }
    case ACTION_INSERT_ELEMENT: {
      if (action.meta) {
        const {
          name,
          key,
        } = action.meta;
        const current = state[name] || {};
        return {
          ...state,
          [name]: {
            ...current,
            values: pushAtKey(
              current.values,
              `${key}._elementsOrder`,
              action.payload,
            ),
            touched: setAtKey(current.touched, `${key}._elementsOrder`, true),
            errors: delAtKey(current.errors, `${key}._elementsOrder`, {
              cascade: true,
            }),
          },
        };
      }
      return state;
    }
    case ACTION_REMOVE_ELEMENT: {
      if (action.meta) {
        const {
          name,
          key,
        } = action.meta;
        const current = state[name] || {};

        let newValues = current.values;

        const elementId = action.payload;
        // NOTE: There's an important reason why we are not using "cascade" here.
        //       Namely, if there's a default value configured for this question
        //       the cascade would reset the form to it's initial state resulting
        //       in the default value being presented instead of an empty list.
        //       What we really want is to indicate that the list MUST be empty.
        newValues = delAtKey(newValues, `${key}._elements.${elementId}`);
        newValues = pullAtKey(newValues, `${key}._elementsOrder`, elementId);

        let newTouched = current.touched;
        newTouched = delAtKey(newTouched, `${key}._elements.${elementId}`, {
          cascade: true,
        });
        newTouched = setAtKey(newTouched, `${key}._elementsOrder`, true);

        let newErrors = current.errors;
        newErrors = delAtKey(newErrors, `${key}._elements.${elementId}`, {
          cascade: true,
        });
        // NOTE: We are deleting a potential error that could refer to the
        //       number of elements. This is legit because the number of
        //       elements has just changed.
        newErrors = delAtKey(newErrors, `${key}._elementsOrder`, {
          cascade: true,
        });

        return {
          ...state,
          [name]: {
            ...state[name],
            values: newValues,
            touched: newTouched,
            errors: newErrors,
          },
        };
      }
      return state;
    }
    case ACTION_MOVE_ELEMENT_UP:
    case ACTION_MOVE_ELEMENT_DOWN: {
      if (action.meta) {
        const {
          name,
          key,
        } = action.meta;
        const getAtKey = createGetAtKey(`${key}._elementsOrder`);
        const current = state[name] || {};
        const elementId = action.payload;
        const elementsOrder = getAtKey(current.values);
        let newElementsOrder;

        const index = indexOf(elementsOrder, elementId);
        if (action.type === ACTION_MOVE_ELEMENT_UP) {
          if (index >= 1) {
            newElementsOrder = [
              ...elementsOrder,
            ];
            newElementsOrder[index] = elementsOrder[index - 1];
            newElementsOrder[index - 1] = elementsOrder[index];
          }
        } else if (action.type === ACTION_MOVE_ELEMENT_DOWN) {
          if (index >= 0 && index < elementsOrder.length - 1) {
            newElementsOrder = [
              ...elementsOrder,
            ];
            newElementsOrder[index] = elementsOrder[index + 1];
            newElementsOrder[index + 1] = elementsOrder[index];
          }
        }
        if (newElementsOrder) {
          return {
            ...state,
            [name]: {
              ...state[name],
              values: setAtKey(
                current.values,
                `${key}._elementsOrder`,
                newElementsOrder,
              ),
            },
          };
        }
      }
      return state;
    }
    case ACTION_INITIALIZE: {
      if (action.meta) {
        const {
          name,
        } = action.meta;
        return setAtKey(state, name, {
          values: action.payload,
        });
      }
      return state;
    }
    case ACTION_CLEAR: {
      if (action.meta) {
        const {
          name,
        } = action.meta;
        return delAtKey(state, name);
      }
      return state;
    }
    case ACTION_CLEAR_ALL: {
      return {};
    }
    case ACTION_REPLACE_FORM_VALUES: {
      const name = action.meta && action.meta.name;
      if (!name) {
        return state;
      }
      return {
        ...state,
        [name]: {
          ...state[name],
          values: action.payload,
        },
      };
    }
    case ACTION_REPLACE_FORM_ERRORS: {
      const name = action.meta && action.meta.name;
      if (!name) {
        return state;
      }
      return {
        ...state,
        [name]: {
          ...state[name],
          errors: action.payload,
        },
      };
    }
    case ACTION_SYNC_FORM_VALUES: {
      const {
        name,
        skip,
      } = action.meta || {};
      if (skip || !name) {
        return state;
      }
      const formValues = action.payload;
      if (!formValues) {
        return {
          ...state,
          [name]: omit(
            {
              ...state[name],
              values: {},
            },
            [
              'errors',
              'touched',
            ],
          ),
        };
      }
      return {
        ...state,
        [name]: omit(
          {
            ...state[name],
            values: formValues,
          },
          [
            'errors',
            'touched',
          ],
        ),
      };
    }
    default:
      return state;
  }
};

export default reducer;
