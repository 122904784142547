import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import includes from 'lodash/includes';
import i18next from 'i18next';
import {
  initReactI18next,
} from 'react-i18next';

const ENGLISH_VALUE = 'en';

const supportedLanguages = [
  'ar',
  'bn',
  'en',
  'es',
  'he',
  'ta',
  'ms',
  'my',
  'zh',
  'pl',
];

const textDirectionOptions = {
  ltr: [
    'bn',
    'en',
    'es',
    'ta',
    'ms',
    'my',
    'zh',
    'pl',
  ],
  rtl: [
    'ar',
    'he',
  ],
};

const maybeUpdateTextDirection = value => find(textDirectionOptions, (languages, direction) => (includes(languages, value)
  ? document.body.classList.add(direction)
  : document.body.classList.remove(direction)));

const getNavigatorLanguage = () => {
  if (typeof navigator !== 'undefined') {
    return navigator.language && navigator.language.substr(0, 2);
  }
  return null;
};

const getSupportedLanguage = (language = getNavigatorLanguage()) => {
  if (supportedLanguages.indexOf(language) >= 0) {
    return language;
  }
  return ENGLISH_VALUE;
};

export const getPreferredLanguage = (
  languagePreference,
  availableLanguages,
  defaultLanguage = null,
) => {
  if (isEmpty(languagePreference)) {
    return defaultLanguage;
  }
  const n = languagePreference.length;
  for (let i = 0; i < n; i += 1) {
    const language = languagePreference[i];
    if (includes(availableLanguages, language)) {
      return language;
    }
  }
  return defaultLanguage;
};

i18next.use(initReactI18next).init(
  {
    interpolation: {
      escapeValue: false,
    },
    lng: getSupportedLanguage(),
    fallbackLng: 'en',
    resources: {
      ar: {
        translation: {
          activity: 'Activity',
          welcome: 'Welcome',
          welcomeBack: 'Welcome back',
          continued:
            'Please click below to continue with the paused questionnaire.',
          completed: 'The following questionnaire has already been completed.',
          expired:
            'Your session has expired. Please follow the link you received or contact your health provider.',
          languageNotAvailable: 'Please choose any available translation.',
          start: 'Start',
          next: 'Next',
          continue: 'Continue',
          complete: 'Finish',
          settings: 'Settings',
          prevQuestion: 'Back',
          pause: 'Pause',
          consent: 'I Consent',
          consent_1: 'By clicking on complete I',
          consent_2: 'consent',
          consent_3: 'to the collection of my data',
          finishQuestionnairePrefix: 'Nearly there!',
          finishQuestionnaireTitle: 'Please confirm to submit your response',
          close: 'Close',
        },
      },
      bn: {
        translation: {
          darkMode: 'ডার্ক মোড',
          activity: 'কার্যক্রম',
          welcome: 'স্বাগতম',
          welcomeBack: 'আবারও স্বাগতম',
          continued:
            'বিরতি নেওয়া প্রশ্নাবলী পুনরায় চালিয়ে যেতে অনুগ্রহ করে নিচে ক্লিক করুন।',
          completed: 'নিম্নলিখিত প্রশ্নাবলী ইতোমধ্যে সম্পন্ন করা হয়ে গেছে।',
          expired:
            'আপনার সেশনের মেয়াদ শেষ হয়ে গেছে। অনুগ্রহ করে আপনার প্রাপ্ত লিংকটি অনুসরণ করুন অথবা আপনার স্বাস্থ্যসেবা সরবরাহকারীর সাথে যোগাযোগ করুন।',
          languageNotAvailable: 'অনুগ্রহ করে উপলভ্য যেকোনো অনুবাদ বেছে নিন।',
          start: 'শুরু করুন',
          next: 'পরবর্তী',
          continue: 'চালিয়ে যান',
          complete: 'শেষ করুন',
          settings: 'সেটিংস',
          prevQuestion: 'পিছনে যান',
          pause: 'বিরতি নিন',
          consent: 'আমি সম্মতি দিচ্ছি',
          consent_1: 'সম্পন্ন করার অপশানে ক্লিক করার মাধ্যমে আমি',
          consent_2: 'সম্মতি দিচ্ছি যে',
          consent_3: 'আমার তথ্য সংগ্রহ করা হবে',
          finishQuestionnairePrefix: 'প্রায় শেষ হয়েছে!',
          finishQuestionnaireTitle:
            'সম্পন্ন করতে অনুগ্রহ করে সমাপ্ত করার অপশানে ক্লিক করুন।',
          settingsLanguage: 'ভাষা',
          settingsFontSize: 'ফন্ট সাইজ নির্ধারণ করুন',
          settingsAnimationSpeed: 'অ্যানিমেশনের গতি',
          disabled: 'নিষ্ক্রিয়',
          slow: 'ধীর',
          normal: 'সাধারণ',
          small: 'ছোট',
          medium: 'মাঝারি',
          large: 'বড়',
          close: 'বন্ধ করুন',
          jwterror:
            'আমরা দুঃখিত কারণ আপনার অ্যাক্সেস টোকেনটি অবৈধ। অনুগ্রহ করে আপনার ডাক্তারের অফিসে যোগাযোগ করুন।',
        },
      },
      en: {
        translation: {
          darkMode: 'Dark mode',
          activity: 'Activity',
          welcome: 'Welcome',
          welcomeBack: 'Welcome back',
          continued:
            'Please click below to continue with the paused questionnaire.',
          completed: 'The following questionnaire has already been completed.',
          expired:
            'Your session has expired. Please follow the link you received or contact your health provider.',
          languageNotAvailable: 'Please choose any available translation.',
          start: 'Start',
          next: 'Next',
          continue: 'Continue',
          complete: 'Finish',
          settings: 'Settings',
          prevQuestion: 'Back',
          pause: 'Pause',
          consent: 'I Consent',
          consent_1: 'By clicking on complete I',
          consent_2: 'consent',
          consent_3: 'to the collection of my data',
          finishQuestionnairePrefix: 'Nearly there!',
          finishQuestionnaireTitle: 'Please press Finish to complete.',
          settingsLanguage: 'Language',
          settingsFontSize: 'Adjust font size',
          settingsAnimationSpeed: 'Animation speed',
          disabled: 'Disabled',
          slow: 'Slow',
          normal: 'Normal',
          small: 'Small',
          medium: 'Medium',
          large: 'Large',
          close: 'Close',
          jwterror:
            "We are sorry but your access token is invalid. Please contact your doctor's office.",
        },
      },
      es: {
        translation: {
          darkMode: 'Modo nocturno',
          activity: 'Actividad',
          welcome: 'Bienvenidos',
          welcomeBack: 'Bienvenido otra vez',
          continued:
            'Por favor, haga click a continuación para continuar con el cuestionario pausado.',
          completed: 'El siguiente cuestionario ya ha sido completado.',
          expired:
            'Su sesión se venció. Por favor, utilice el link que recibió o póngase en contacto con su proveedor de salud.',
          languageNotAvailable:
            'Por favor, elija alguna de las traducciones disponibles.',
          start: 'Comenzar',
          next: 'Siguiente',
          continue: 'Continuar',
          complete: 'Finalizar',
          settings: 'Configuración',
          prevQuestion: 'Regresar',
          pause: 'Pausar',
          consent: 'Doy mi consentimiento',
          consent_1: 'Al hacer click en completar, yo',
          consent_2: 'doy mi consentimiento',
          consent_3: 'para la recolección de mis datos',
          finishQuestionnairePrefix: '¡Ya casi terminamos!',
          finishQuestionnaireTitle:
            'Por favor, presione Finalizar para completar.',
          settingsLanguage: 'Idioma',
          settingsFontSize: 'Elegir tamaño de fuente',
          settingsAnimationSpeed: 'Velocidad de la animación',
          disabled: 'Deshabilitar',
          slow: 'Despacio',
          normal: 'Normal',
          small: 'Pequeño',
          medium: 'Mediano',
          large: 'Grande',
          close: 'Cerrar',
          jwterror:
            'Lo sentimos, pero su token de acceso es inválido. Por favor, póngase en contacto con la oficina de su doctor.',
        },
      },
      he: {
        translation: {
          activity: 'פעילות',
          welcome: 'ברוך הבא/שלום',
          welcomeBack: 'ברוך הבא שוב',
          continued: 'אנא לחץ למטה כדי להמשיך בשאלון',
          completed: 'הפיעלות המבוקשת כבר הושלמה',
          expired:
            'פג תוקפה של הפעילות הנוכחית, יש ללחוץ הקישור שהתקבל במכשיר הנייד או לפנות לגורם',
          languageNotAvailable: 'בחר כל תרגום זמין',
          start: 'התחל',
          next: 'הבא',
          continue: 'המשך',
          complete: 'נגמר',
          settings: 'הגדרות',
          prevQuestion: 'אחורה',
          pause: 'הפסקה',
          consent: 'אני מסכיםמסכימה',
          consent_1: 'בלחיצה על השלמה  אני',
          consent_2: 'מסכיםמסכימה',
          consent_3: 'שנתוניי יאספו',
          finishQuestionnairePrefix: 'כמעט שם',
          finishQuestionnaireTitle: 'אנא אשר על מנת להגיש את התגובה',
          settingsLanguage: 'שפה',
          settingsFontSize: 'התאם עבור גודל',
          settingsAnimationSpeed: 'מהירות אנימציה',
          disabled: 'הופסק',
          slow: 'איט',
          normal: 'רגיל',
          small: 'קטן',
          medium: 'בינוני',
          large: 'גדול',
          close: 'סגור',
          jwterror:
            'אנו מצטערים אך אסימון הגישה שלך אינו בתוקף. אנא צרו קשר עם מזכירות הרופא שלך',
        },
      },
      ta: {
        translation: {
          activity: 'செயல்பாடு',
          welcome: 'வணக்கம்',
          welcomeBack: 'மீண்டும் உங்களை சந்திப்பதிள் மகிழ்ச்சி',
          continued: 'இடைநிறுத்தப்பட்ட கேள்வியாளருடன் தொடர கீழே கிளிக் செய்க.',
          completed: 'பின்வரும் வினாத்தாள் ஏற்கனவே பூர்த்தி செய்யப்பட்டுள்ளது.',
          expired:
            'உங்கள் அமர்வு காலாவதியாகி விட்டது. நீங்கள் பெற்ற இணைப்பைப் பின்தொடரவும் அல்லது உங்கள் சுகாதார வழங்குநரைத் தொடர்பு கொள்ளவும்.',
          languageNotAvailable:
            'கிடைக்கக்கூடிய எந்த மொழிபெயர்ப்பையும் தேர்வு செய்யவும்',
          start: 'தொடக்கம்',
          next: 'அடுத்தது',
          continue: 'தொடர',
          complete: 'பினிஷ்',
          settings: 'அமைப்புகள்',
          prevQuestion: ' முந்தைய',
          pause: ' இடைநிறுத்தம்',
          consent: ' நான் ஒப்புக்கொள்கிறேன்',
          consent_1: 'முடிவு என்ற வாக்கியத்தை கிளிக் செய்வதன் மூலம் நான்',
          consent_2: 'ஒப்புதல்',
          consent_3: 'எனது தரவு சேகரிப்புக்கு',
          finishQuestionnairePrefix:
            'நீங்கள் முடிக்க நெருக்கமாக இருக்கிறீர்கள்!',
          finishQuestionnaireTitle:
            'உங்கள் பதிலைச் சமர்ப்பிக்க உறுதிப்படுத்தவும்',
          settingsLanguage: ' மொழி',
          settingsFontSize: ' எழுத்துரு அளவை சரிசெய்யவும்',
          settingsAnimationSpeed: ' அனிமேஷன் வேகம்',
          disabled: ' நிறுத்தி',
          slow: ' மெதுவாக',
          normal: 'இயல்பான',
          small: ' சிறிய',
          medium: 'நடுத்தர',
          large: ' பெரிய',
          close: ' நெருக்கமான',
          jwterror:
            ' எங்களை மன்னிக்கவும். உங்கள் அணுகல் குறியீடு தவறானது. தயவுசெய்து உங்கின் மருத்துவமனையை தொடர்புக்கொள்ளுந்கள்.',
        },
      },
      ms: {
        translation: {
          activity: 'Aktiviti',
          welcome: 'Selamat datang!',
          welcomeBack: 'Selamat kembali',
          continued:
            'Sila klik di bawah untuk meneruskan dengan soal selidik yang dihentikan seketika.',
          completed: 'Soal selidik berikut telah selesai.',
          expired:
            'Sesi anda telah tamat tempoh. Sila ikut pautan yang anda terima atau hubungi pembekal kesihatan anda.',
          languageNotAvailable: 'Sila pilih terjemahan yang sedia ada',
          start: 'Mula',
          next: 'Seterusnya',
          continue: 'Teruskan',
          complete: 'Selesai',
          settings: 'Tetapan',
          prevQuestion: 'Kembali',
          pause: 'Berhenti seketika',
          consent: 'Saya bersetuju',
          consent_1: 'Dengan mengklik pada "Selesai", saya',
          consent_2: 'bersetuju',
          consent_3: 'dengan pengumpulan data saya.',
          finishQuestionnairePrefix: 'Hampir sampai!',
          finishQuestionnaireTitle:
            'Sila sahkan untuk menghantar maklum balas anda',
          settingsLanguage: 'Bahasa',
          settingsFontSize: 'Melaraskan saiz huruf',
          settingsAnimationSpeed: 'Kelajuan animasi',
          disabled: 'Dilumpuhkan',
          slow: 'perlahan',
          normal: 'biasa',
          small: 'kecil',
          medium: 'sederhana',
          large: 'besar',
          close: 'tutup',
          jwterror:
            'Maaf, token akses anda tidak sah. Sila hubungi pejabat doktor anda.',
        },
      },
      my: {
        translation: {
          activity: 'လႈပ္ရွားမႈ',
          close: 'ပိတ္သည္',
          complete: 'ၿပီးဆံုးသည္',
          completed: 'ေအာက္ပါ ေမးခြန္းလႊာမ်ားကို ျဖည့္စြက္ၿပီးျဖစ္သည္။',
          consent: 'ကၽြႏု္ပ္ သေဘာတူသည္',
          consent_1: 'ၿပီးဆံုးသည္ကို ႏွိပ္ျခင္းျဖင့္ ကၽြႏု္ပ္',
          consent_2: 'ကၽြႏု္ပ္၏ အခ်က္အလက္မ်ားကို',
          consent_3: 'ေကာက္ယူရန္ ကၽြႏု္ပ္သေဘာတူသည္',
          continue: 'ဆက္သြားရန္',
          continued:
            'ရပ္ထားေသာ ေမးခြန္းလႊာကို ဆက္လုပ္ရန္ ေက်းဇူးျပဳ၍ ေအာက္တြင္ ႏွိပ္ပါ',
          darkMode: 'ေမွာင္သည့္ မုဒ္',
          disabled: 'မလုပ္ႏိုင္ေတာ့ပါ',
          expired:
            'သင္လုပ္ငန္း သက္တမ္းကုန္သြားၿပီ၊ ေက်းဇူးျပဳ၍ သင္ရရွိထားေသာ လင့္ခ္ကို လိုက္ပါ သို႔မဟုတ္ သင္၏ က်န္းမာေရး ၀န္ေဆာင္ေပးသူကို ဆက္သြယ္ပါ။',
          finishQuestionnairePrefix: 'ေရာက္ကာနီးပါၿပီ',
          finishQuestionnaireTitle:
            'ေက်းဇူးျပဳ၍ အဆံုးသတ္ရန္ ၿပီးဆံုးရန္ ကို ႏွိပ္ပါ',
          jwterror:
            'စိတ္မေကာင္းပါဘူး။ ဒါေပမယ့္ သင္၏ လက္မွတ္မွာ သံုးမရပါ၊ ေက်းဇူးျပဳ၍ သင့္ဆရာ၀န္၏ ရံုးကို ဆက္သြယ္ပါ',
          languageNotAvailable:
            'ေက်းဇူးျပဳ၍ ရရွိနိုင္ေသာ ဘာသာျပန္တစ္ခုခုကို ေရြးခ်ယ္ပါ။',
          large: 'အႀကီး',
          medium: 'အလယ္အလတ္',
          next: 'ဆက္သြားရန္',
          normal: 'ပံုမွန္',
          pause: 'ရပ္ထားရန္',
          prevQuestion: 'ေနာက္သို႔ျပန္သြားရန္',
          settings: 'ဆက္တင္မ်ား',
          settingsAnimationSpeed: 'ရုပ္ပံု အျမန္ႏႈန္',
          settingsFontSize: 'ေဖာင့္အရြယ္ခ်ိန္ရန္',
          settingsLanguage: 'ဘာသာစကား',
          slow: 'ႏွးရန္',
          small: 'အေသး',
          start: 'စတင္ရန္',
          welcome: 'ႀကိဳဆုိပါသည္',
          welcomeBack: 'ျပန္လည္ ႀကိဳဆိုပါသည္',
        },
      },
      zh: {
        translation: {
          activity: 'Activity',
          welcome: '欢迎',
          welcomeBack: '欢迎回来',
          continued: '请点击以下来继续暂停的问卷。',
          completed: '下列的活动已经完成。',
          expired: '您的会话过期了。请点击您收到的链接或跟有关机构联系。',
          languageNotAvailable: '请选择任何可用的翻译',
          start: '开始',
          next: '下一个',
          continue: '继续',
          complete: '结束',
          settings: '设置',
          prevQuestion: '上一题',
          pause: '暂停',
          consent: '我同意',
          consent_1: '通过点击"完成"，您即将',
          consent_2: '同意',
          consent_3: '个人资料被收集。',
          finishQuestionnairePrefix: '就快完成了！',
          finishQuestionnaireTitle: '请确认以提交您的回复',
          settingsLanguage: '语言',
          settingsFontSize: '调整字体大小',
          settingsAnimationSpeed: '动画速度',
          disabled: '禁用',
          slow: '慢',
          normal: '正常',
          small: '小',
          medium: '中',
          large: '大',
          close: '关闭',
          jwterror: '抱歉，您的访问令牌已经无效。请联系您的医疗所。',
        },
      },
      pl: {
        translation: {
          activity: 'Aktywność',
          welcome: 'Witamy',
          welcomeBack: 'Witamy ponownie',
          continued:
            'Kliknij poniżej aby powrócić do zapauzowanego kwestionariusza.',
          completed: 'Ten kwestionariusz został już ukończony.',
          expired:
            'Twoja sesja wygasła. Użyj otrzymanego linku, lub skontaktuj się z właściwym przedstawicielem opieki zdrowotnej.',
          start: 'Rozpocznij',
          next: 'Następne',
          continue: 'Kontynuuj',
          complete: 'Zakończ',
          settings: 'Ustawienia',
          prevQuestion: 'Poprzednie',
          pause: 'Zapauzuj',
          consent: 'Wyrażam zgodę',
          consent_1: 'Klikając zakończ',
          consent_2: 'wyrażam zgodę',
          consent_3: 'na gromadzenie i przetwarzanie moich danych',
          finishQuestionnairePrefix: 'Wszystko gotowe!',
          finishQuestionnaireTitle: 'Możesz teraz zakończyć.',
          settingsLanguage: 'Język',
          settingsFontSize: 'Dostosuj rozmiar cznionki',
          settingsAnimationSpeed: 'Szybkość animacji',
          disabled: 'Wyłączone',
          slow: 'Wolno',
          normal: 'Normalnie',
          small: 'Mały',
          medium: 'Średni',
          large: 'Duży',
          close: 'Zamknij',
          jwterror:
            'Przykro nam ale token jest nieprawidłowy. Prosimy skontaktować się z gabinetem swojego lekarza.',
        },
      },
    },
  },
  err => err && console.error(err),
);

export {
  ENGLISH_VALUE,
  supportedLanguages,
  getNavigatorLanguage,
  getSupportedLanguage,
  maybeUpdateTextDirection,
};
