import {
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__UNKNOWN,
  QUESTION_TYPE__DATE,
  QUESTIONNAIRE_MODE_OPTIONS,
} from './Questionnaire';

import {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_DIVERSE,
  GENDER_UNKNOWN,
} from './patient';

import {
  RECIPIENT_ETHNICITIES,
  RECIPIENTS_PRE_C_ETHNICITY,
} from './collections/Recipients';

// PATIENT
export const VARIABLE_ID__RECIPIENT_ID = 'ameRKJteqaENrA952';
export const VARIABLE_ID__PRIMARY_IDENTIFIER = 'jXtYzpfhEG244zjua';
export const VARIABLE_ID__MODE = 'WWj8HEX28XX2LCPoF';
export const VARIABLE_ID__NAME = 'bDaxwY5xutyHikRJ3';
export const VARIABLE_ID__NAME_FIRST = 'GtQgBdLoWPJdDdp5r';
export const VARIABLE_ID__NAME_LAST = 'MwdDGEJS4fzNLLnib';
export const VARIABLE_ID__PHONE = 'Zygevpkh5JvoxS65a';
export const VARIABLE_ID__PHONE_2 = 'Zgrbm2LMF55PfaZRu';
export const VARIABLE_ID__EMAIL = 'N7bX6fTtD5uwQBK9M';
export const VARIABLE_ID__ADDRESS = 'C7KZFW9EX9DYNG6Z7';
export const VARIABLE_ID__LANGUAGE = 'TnjXiWN3wugWAuvjH';
export const VARIABLE_ID__AGE = 'nXKK2xzJEGLWYkzRX';
export const VARIABLE_ID__GENDER = 'vnCCpS3y7hrWbM4TB';
export const VARIABLE_ID__ETHNICITY = 'iGjEtL82n6M54KwKf';
export const VARIABLE_ID__ETHNICITY_SINGAPORE = 'popozmitinogikimm';
export const VARIABLE_ID__ETHNICITY_PRE_C = 'zGjEtL67n6M62KwKf';
export const VARIABLE_ID__ETHNICITY_OTHER = '7FA5SqG84KPYZNze6';
export const VARIABLE_ID__FAMILY_HISTORY = 'jDrwheyM9Ksh2ufum';
export const VARIABLE_ID__FAMILY_RELATIONSHIP = '9CwSuajkKyAR5opQE';
export const VARIABLE_ID__FAMILY_DIAGNOSIS = 'ABQaDEbzxmdwCcJ58';

export const VARIABLE_ID__ADDRESS_STREET = 'Km6RaNdBXhdh9qRXF';
export const VARIABLE_ID__ADDRESS_SUBURB = 'GcD2oFPERD2m7Xgsy';
export const VARIABLE_ID__ADDRESS_CITY = 'h75oPEQhmjxxiKRBq';
export const VARIABLE_ID__ADDRESS_STATE = 'dE6y4MuKoPhoAteL5';
export const VARIABLE_ID__ADDRESS_CODE = 'm6kBjFgmNvPM929vS';
export const VARIABLE_ID__ADDRESS_COUNTRY = 'mpenZaoTC48LQmDhm';

// Mercy Ascot
export const VARIABLE_ID__TARGET_EDI = 'yHc26tKzJRuAC2pEX';
export const VARIABLE_ID__SPECIALIST = 'g36FnAPjWC8Eu6G2L';
export const VARIABLE_ID__DISCHARGING_CLINICIAN = 'twvrBywrmK85Kwj9q';

// ...
export const VARIABLE_ID__IDENTIFIER = 'xkyxb8DYhGbKoqvRq';
export const VARIABLE_ID__NATIONAL_ID = 'rpQx3L3uNWvcokfsS';
export const VARIABLE_ID__ENCOUNTER_ID = 'pKNQJXNxiSFQERiDG';
export const VARIABLE_ID__CAREGIVER_NAME = 'ig7gZPy5TuNaCtpE4';
export const VARIABLE_ID__CAREGIVER_PHONE = 'gBWQQzWsTY6uaMSBa';
export const VARIABLE_ID__CAREGIVER_EMAIL = 'yQYS3mb6ugBSjo47N';
export const VARIABLE_ID__RECRUITER_NAME = 'YHqwsLWtF2CwdDtEC';
export const VARIABLE_ID__RECRUITMENT_DATE = 'Eu9hwBQp6F5ZJt3BD';
export const VARIABLE_ID__INTERVIEWER_NAME = 'qWxRWRxqQG5uaKHut';
export const VARIABLE_ID__INTERVIEW_DATE = 'ixSQfse2ENMjm7EE4';
export const VARIABLE_ID__ORGANIZATION_NAME = 'rahKhoqxuAnnhfQcJ';
export const VARIABLE_ID__DISCH_CLASS = 'qnkQ6x39Ybu5tmpYy';
export const VARIABLE_ID__DISCH_DATE = 'hRnAWTYuuiwK9FZDZ';
export const VARIABLE_ID__DISCIPLINE = 'vpiieexHTk4o3cysf';
export const VARIABLE_ID__WARD_AND_CLINIC = 'rB5pRHYmnwoBveioE';
export const VARIABLE_ID__WARD_CODE = 'BQRdTGyztKBGue7u4';
export const VARIABLE_ID__PHONE_H = 'E6RjNn8GGmRy7rdeS';
export const VARIABLE_ID__PHONE_M = 'ihagePCtg4nTm9vMP';
export const VARIABLE_ID__PHONE_W = 'vvEvPfkEoj9f7ZgGX';
export const VARIABLE_ID__ADM_VISIT_TIME = 'aNnze9w2nnydGJL7q';
export const VARIABLE_ID__ADM_VISIT_DATE = 'NR2TDEPx9h9J56iWK';
export const VARIABLE_ID__PACS = 'ogr7AHSyawMFvxHdy';
export const VARIABLE_ID__TRTOU = 'T7k7tegmQe4wBTRzQ';
export const VARIABLE_ID__MARITAL_STATUS = 'gSH6mteXvGrwQSaeE';
export const VARIABLE_ID__BMI = 'r3CdZQPckpvdCvSE5';
export const VARIABLE_ID__RELIGION = '3mKkwmq5tcucHngu8';
export const VARIABLE_ID__RELIGION_OTHER = 'DTxwDyqryYgvzXbyx';
export const VARIABLE_ID__RESIDENCY_STATUS = 'WRGC8XpXWCMPgdvN7';
export const VARIABLE_ID__RESIDENCY_LENGTH = 'AnSBMMpBh3AwxJQsP';
export const VARIABLE_ID__FOREIGN_NATIONAL = '39QDdzK3r7kFW5qYK';
export const VARIABLE_ID__BIRTH_COUNTRY = 'SNcsqrweSaHPifmNY';
export const VARIABLE_ID__REFERRAL_SOURCE = 'eoD2srjaX5xmKhayX';
export const VARIABLE_ID__REFERRAL_SOURCE_OTHER = 'BzTeNzzLnxynARwK2';
export const VARIABLE_ID__TOC_DESTINATION = 'ZQa7ixoZZndBo6dKE';
export const VARIABLE_ID__TOC_DESTINATION_OTHER = 'GdbRY5yJsQyfFkfmA';
export const VARIABLE_ID__TOC_DATE = 'YnxtjnYhewcCfXs7j';
export const VARIABLE_ID__EMPLOYMENT_TYPE = 'dcwmmnn9qyQigYRDo';
export const VARIABLE_ID__INCOME_SOURCE = 'd4J6yvinCDLBYMpC4';
export const VARIABLE_ID__READMISSIONS = '3A9gpWeNusWe2WXsy'; // deprecated, but still used in CSV exports
export const VARIABLE_ID__NUMBER_OF_READMISSIONS = 'is6DymP6ufvsoSfGe';
export const VARIABLE_ID__KIN_NAME = 'B7R88PdoJe2MDsuje';
export const VARIABLE_ID__KIN_RELATIONSHIP = '784KjKCq58tfXcCKs';
export const VARIABLE_ID__KIN_PHONE = '6kvqFnPhMFbPXbwYj';
export const VARIABLE_ID__DUP_ANTIPSYCHOTICS = 'syjcFFFT4EKGTQYny';
export const VARIABLE_ID__BIRTH_DATE = '24qwgnRzgkumaPpQh'; // TODO: Change it to "DOB_YEAR_MONTH_DAY"
export const VARIABLE_ID__RECIPIENT_DOB = '5JfWnNKGyJHtgLmLq'; // structured DOB
export const VARIABLE_ID__TREATMENT_TYPE = '8iffnNKKyJHtgLmLq';
export const VARIABLE_ID__DEPARTMENT = 'gaofNykHg83pujxrc';
export const VARIABLE_ID__SUB_GROUP = 'S77GrbJmpgaZRKMrk';
export const VARIABLE_ID__ORGANIZATION_UNIT = 'hGhN7fHaYhYjPZ6iv';
export const VARIABLE_ID__META_FIELD_1 = 'jBMLTarkbTXeJTs7E';
export const VARIABLE_ID__META_FIELD_2 = '3hfQ5pNwM4JnQuTdm';
export const VARIABLE_ID__META_FIELD_3 = 'o5e8swwruvPiWoD3Q';
export const VARIABLE_ID__META_FIELD_NUMBER_1 = 'AasbqCke52ivibe7D';
export const VARIABLE_ID__META_FIELD_NUMBER_2 = '69DNSJD4rokE3pJjN';
export const VARIABLE_ID__META_FIELD_NUMBER_3 = 'JdE34ydQCHp8p5ZKr';

// PARTICIPATION
export const VARIABLE_ID__STUDY_NO = 'KKR7qswiJAC76GaFj'; // TODO: Change name to "custom study number"
export const VARIABLE_ID__STUDY_NO_TERMS = 'luWaWopKULtiUdevu';
export const VARIABLE_ID__PARTICIPATION_ID = '6kmeas93reeg4giNQ';
export const VARIABLE_ID__PARTICIPATION_TRACK_ID = 'QPtfeRYbRC4QCBu6j';
export const VARIABLE_ID__PARTICIPATION_SEQUENCE_NO = 'phxaLKpGwsQGivRa2';
export const VARIABLE_ID__PARTICIPATION_PROJECT_ID = 'zCmnFXKeBQbFGQJmA';
export const VARIABLE_ID__PARTICIPATION_PROJECT_NAME = 'zQpPP7KeF9hq5fhEJ';
export const VARIABLE_ID__PARTICIPATION_SUBSPECIALTY = 'WFuAjfrYp4ubkH6JG';
export const VARIABLE_ID__ACCEPTED = 'LwkbbYKRpvk7ofXAC';
export const VARIABLE_ID__CONDITION = 'EdoaSGJimsq4GtKT9';
export const VARIABLE_ID__LIST_OF_DIAGNOSES = 'SHMqzbE8Jczp5QoKE';
export const VARIABLE_ID__PRIMARY_DIAGNOSIS = 'ipYyzqRCcCKqAZ2hY';
export const VARIABLE_ID__SECONDARY_DIAGNOSIS = 'SfkvDAPW9orH7fdi5';
export const VARIABLE_ID__STATUS_IN_PROJECT = 'GEmp2h5so6Tg9c8pE';
export const VARIABLE_ID__LENGTH_OF_STAY = 'HBkJYvb3aeFQeoex4';
export const VARIABLE_ID__CLINICIAN = 'a4qwgnRzgkumaPpQh';
export const VARIABLE_ID__CASE_MANAGER = '8RFByz2pxSKMAkAXA';
export const VARIABLE_ID__CLINICIAN_NAME = 'Z8tWGzDL638Er328n';
export const VARIABLE_ID__CASE_MANAGER_NAME = 'GXLDnY7bWSifDzSLW';
export const VARIABLE_ID__PHONE_SMS_NOTIFICATIONS = 'QEW2jTkkspvqcDRNW';
export const VARIABLE_ID__PATIENT_BASELINE = 'tEr464KwqZtvC2Dd3';
export const VARIABLE_ID__CONSENT_OBTAINED = 'Dd2C7cqDrDfeJ66oH';
export const VARIABLE_ID__CHEMOTHERAPY_CYCLE_NO = 'm2mKe1lOtuUSUofas';
export const VARIABLE_ID__RADIOTHERAPY_WEEK_NUMBER = 'OViGeg67ikru4itOH';
export const VARIABLE_ID__IMMUNOLOGICAL_STATUS = '2YgYbCZ5uCm4ghwhY';
export const VARIABLE_ID__EDUCATION_LEVEL = 'dGs44SL77erMeJg98';
export const VARIABLE_ID__ALCOHOL_USE = 'b6YMqMk8aLBpqAuz7';
export const VARIABLE_ID__HYPERTENSION_AND_DIABETES = 'MNikpc976gLHh8wJL';
export const VARIABLE_ID__PRIOR_ATRIAL_FIBRILLATION = 'dWviMkAuRe6CxQPn4';
export const VARIABLE_ID__PRIOR_MYOCARDIAL_INFARCTION = 'rPf3TKZ3zFYAHKY4k';
export const VARIABLE_ID__RENAL_DYSFUNCTION = 'QpCe5u5rtebcs2T62';
export const VARIABLE_ID__SMOKING_STATUS = 'DEF2uTNRKi5Yx2Ci3';
export const VARIABLE_ID__PARTICIPATION_BED_NUMBER = 'Jz7cjKJudEn6bZWY7';
export const VARIABLE_ID__PARTICIPATION_CATEGORY = 'Pd4vkqpA2QnPXejdi';
export const VARIABLE_ID__PARTICIPATION_EMR_SYNC = 'hCbkuQbXpmQbF2GZr';
export const VARIABLE_ID__PARTICIPATION_HALL = 'vQNdYzuvyToEFcsBQ';
export const VARIABLE_ID__PARTICIPATION_ROW = 'ZewtdsLFBvZazRvNP';
export const VARIABLE_ID__PARTICIPATION_BED = 'wrZHuMWCWoG5YagNT';

// MESSAGE
export const VARIABLE_ID__MESSAGE_TYPE = 'JyCeLwb3jn7u9Y3FQ';
export const VARIABLE_ID__MESSAGE_ORIGINAL_TYPE = 'MexgwXkpb6FwZdFx6';
export const VARIABLE_ID__MESSAGE_ORIGINAL_ID = 'q6eARbDMfz4ekq46q';
export const VARIABLE_ID__MESSAGE_PROCESSED_AT = 'fahjuv6djQTmxrhk5';
export const VARIABLE_ID__MESSAGE_RECEIVED_AT = 'KT33q52RZ5GCvaX4n';
export const VARIABLE_ID__MESSAGE_PATIENT_ID = 'MfRM6hLRogF6gx4Cv';
export const VARIABLE_ID__MESSAGE_PATIENT_ID_AUTHORITY = '4JryFvTr37h4WjLme';
export const VARIABLE_ID__MESSAGE_PROJECT_ID = 'SMq46Szy4w3exKWy7';
export const VARIABLE_ID__MESSAGE_PROVIDER_ID = 'kNMbNvHWDu79zkD2W';
export const VARIABLE_ID__MESSAGE_PROVIDER_ID_AUTHORITY = 'fGGDRRtbxBQE3CxCq';
export const VARIABLE_ID__MESSAGE_PROVIDER_NAME = 'Sw47Ko2rops9TApDD';
export const VARIABLE_ID__MESSAGE_SOURCE_FILE_NAME = '96Y3ATWTaNSSatj5w';

// ACTIVITY
export const VARIABLE_ID__ACTIVITY_ID = 'mHdZ7c73yLKSHLeuW';
export const VARIABLE_ID__ACTIVITY_SCHEDULE = 'BxowbARzGieu6cDBd';
export const VARIABLE_ID__ACTIVITY_MILESTONE_ID = '7xBEpNt3TnfvtWtRa';
export const VARIABLE_ID__ACTIVITY_MILESTONE_NAME = '9mDcz3zodofKbzfba';
export const VARIABLE_ID__ACTIVITY_CLINICIAN_ID = '3HoroMwZefGteNn2u';
export const VARIABLE_ID__ACTIVITY_CASE_MANAGER_ID = 'YriN2Q6vkRBDRvyNP';
export const VARIABLE_ID__ACTIVITY_CLINICIAN_NAME = 'J2Dbv3ZpqxcSaAbNt';
export const VARIABLE_ID__ACTIVITY_PATIENT_TYPE = 'CPRvCWMznsyTcYgJ2';
export const VARIABLE_ID__ACTIVITY_TEMP = 'YPsgi2KbJPogiyT6Q';
export const VARIABLE_ID__ACTIVITY_SPO2 = 'jhvM9WGFKvWRkrLkM';
export const VARIABLE_ID__ACTIVITY_SYSTOLIC_BP = 'jhvM9WGFKvWRkrLkM';
export const VARIABLE_ID__ACTIVITY_DIASTOLIC_BP = 'yP6E2JjvM6WcksBG6';
export const VARIABLE_ID__ACTIVITY_HR = '3R8TZWyuATrhoXxnq';
export const VARIABLE_ID__ACTIVITY_DYSPNOEA = '8j6L7yZCSYLT2EFHg';
export const VARIABLE_ID__ACTIVITY_MEDS = 'sHoC5ftxYbhNxLYh4';
export const VARIABLE_ID__ACTIVITY_SI = 'i7vpfdiePMsSgkf4e';

// ANSWERS_SHEET
export const VARIABLE_ID__ANSWERS_SHEET_ID = 'rPqkgzyDjxT42em4Y';
export const VARIABLE_ID__ANSWERS_SHEET_USER_AGENT_DEVICE = 'pafotmakibaergasl';
export const VARIABLE_ID__ANSWERS_SHEET_USER_AGENT_OS = 'wemunulakeubcohza';
export const VARIABLE_ID__ANSWERS_SHEET_USER_AGENT_BROWSER =
  'uzifipuluknocuwiz';
export const VARIABLE_ID__ANSWERS_SHEET_SECONDS_TO_COMPLETE =
  'zaznozharruwumwah';
export const VARIABLE_ID__ANSWERS_SHEET_LANGUAGE = 'waziowladurvettok';
export const VARIABLE_ID__ANSWERS_SHEET_TIMEZONE = 'zowkiomaipipukibu';

// MILESTONE
export const VARIABLE_ID__MILESTONE_ID = 'hmAYpxiC4kLD4MuLs';
export const VARIABLE_ID__MILESTONE_NAME = 'j3zTQAGmkkZSKT4Lz';

// PROJECT
export const VARIABLE_ID__PROJECT_ID = 'EzGnxbEd2Gbxfec7E';
export const VARIABLE_ID__PROJECT_NAME = 'GTPBKJxDb72pWY2vv';

// NOTE: Variable.key will be used to create Recipient object.
const BUILT_IN_VARIABLES = [
  {
    _id: VARIABLE_ID__KIN_NAME,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Next of Kin Name',
      title: 'Who is your next of Kin?',
    },
  },
  {
    _id: VARIABLE_ID__KIN_RELATIONSHIP,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Next of Kin Relationship',
      title: 'What is your relationship with your Next of Kin?',
    },
  },
  {
    _id: VARIABLE_ID__KIN_PHONE,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Next of Kin Phone',
      title: "What is your relationship Next of Kin's phone number?",
    },
  },
  {
    _id: VARIABLE_ID__BIRTH_DATE,
    scopeName: '@recipient',
    nativeKey: 'dateOfBirth.yearMonthDay',
    key: 'dateOfBirth.yearMonthDay',
    // TODO: We could consider doing something like:
    // setter: {
    //   variableId: VARIABLE_ID__RECIPIENT_DOB,
    //   formula: {
    //     type: 'toDOB',
    //   },
    // },
    // getter: {
    //   formula: {
    //     // ...
    //   },
    // },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Date of Birth',
      title: 'What is your date of birth?',
    },
  },
  {
    _id: VARIABLE_ID__RECIPIENT_DOB,
    scopeName: '@recipient',
    nativeKey: 'dateOfBirth',
    jsonSchema: {
      type: 'object',
      properties: {
        yearMonthDay: {
          type: 'string',
        },
        year: {
          type: 'string',
        },
        isoString: {
          type: 'string',
        },
      },
      required: [],
    },
  },
  {
    _id: VARIABLE_ID__TREATMENT_TYPE,
    scopeName: '@participation',
    valueSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Treatment type',
      title: 'What is patient treatment type?',
      choices: [
        {
          id: '76uYqxLxK2u7EQeg3',
          value: 'Robotic Prostatectomy',
        },
        {
          id: '8sWfQTqETGMCbbHqA',
          value: 'Proton',
        },
        {
          id: 'LauaZwtFuvy7xib3Q',
          value: 'Branchytherapy',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__MODE,
    key: 'mode',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Mode',
      title: 'What is the current survey mode?',
      choices: [
        {
          id: 'i6Kcozu8hoR6EdZ7v',
          ...QUESTIONNAIRE_MODE_OPTIONS[0],
        },
        {
          id: 'mLwAQWASmWvx9gboy',
          ...QUESTIONNAIRE_MODE_OPTIONS[1],
        },
        {
          id: 'qzqyyAAvQGWLgzkTb',
          ...QUESTIONNAIRE_MODE_OPTIONS[2],
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__NAME,
    variable: '@recipient.name',
    scopeName: '@recipient',
    nativeKey: 'name',
    jsonSchema: {
      type: 'string',
    },
    key: 'name',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Name',
      title: 'What is your name?',
    },
    fhir: {
      key: 'name',
      operator: 'push',
      value: {
        text: '$value',
      },
    },
  },
  {
    _id: VARIABLE_ID__NAME_FIRST,
    variable: '@recipient.firstName',
    scopeName: '@recipient',
    nativeKey: 'firstName',
    jsonSchema: {
      type: 'string',
    },
    key: 'firstName',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'First Name',
      title: 'What is your first name?',
    },
  },
  {
    _id: VARIABLE_ID__NAME_LAST,
    variable: '@recipient.lastName',
    scopeName: '@recipient',
    nativeKey: 'lastName',
    jsonSchema: {
      type: 'string',
    },
    key: 'lastName',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Last Name',
      title: 'What is your last name?',
    },
  },
  {
    _id: VARIABLE_ID__PHONE,
    scopeName: '@recipient',
    nativeKey: 'phones.0.number',
    immutable: true, // note this is just an accessor property
    key: 'phones.0.number',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Phone',
      title: 'What is your phone number?',
    },
    fhir: {
      key: 'telecom',
      operator: 'push',
      value: {
        system: 'phone',
        value: '$value',
      },
    },
  },

  {
    _id: VARIABLE_ID__PHONE_2,
    scopeName: '@recipient',
    nativeKey: 'phones.1.number',
    immutable: true, // note this is just an accessor property
    key: 'phones.1.number',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Phone (2nd)',
      title: 'What is your phone number?',
    },
    fhir: {
      key: 'telecom',
      operator: 'push',
      value: {
        system: 'phone',
        value: '$value',
      },
    },
  },
  {
    _id: VARIABLE_ID__EMAIL,
    scopeName: '@recipient',
    nativeKey: 'emails.0.address',
    immutable: true, // this just just an accessor property
    key: 'emails.0.address',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Email',
      title: 'What is your email address?',
    },
    fhir: {
      key: 'telecom',
      operator: 'push',
      value: {
        system: 'email',
        value: '$value',
      },
    },
  },
  {
    _id: VARIABLE_ID__REFERRAL_SOURCE_OTHER,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Other Referral Source',
      title: 'Please specify the referral source',
    },
  },
  {
    _id: VARIABLE_ID__REFERRAL_SOURCE,
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Referral Source',
      title: 'Source of referral to IMH for current contact',
      choices: [
        {
          id: 'zfxrAaGcM7eq3eehH',
          value: '1',
          label: 'Hospital',
        },
        {
          id: '7PLgZbHZW9EjdB5nt',
          value: '2',
          label: 'G.P. or Polyclinic',
        },
        {
          id: 'SJhztwavzQDW7mQ5m',
          value: '3',
          label: 'Private psychiatrist',
        },
        {
          id: 'zALmet9Nur583TYi3',
          value: '4',
          label: 'Police',
        },
        {
          id: 'cNGACj5EmuBWX7WbR',
          value: '5',
          label: 'Court',
        },
        {
          id: 'RMar98w3igQxPJg98',
          value: '6',
          label: 'Family/ Relatives',
        },
        {
          id: 'vXnGDDBNNqWrtLcrp',
          value: '7',
          label: 'Friends',
        },
        {
          id: 'rgBNtyE6ycAjWkC9M',
          value: '8',
          label: 'Welfare organization / Counsellor',
        },
        {
          id: 'YrYcjZRfhptWBEemf',
          value: '9',
          label: 'Self',
        },
        {
          id: 'eb6fNp6XWJpw7bCge',
          value: '10',
          label: 'Others',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__FOREIGN_NATIONAL,
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Foreign National',
      title: 'Are you a foreign born national?',
      choices: [
        {
          id: '6dczh2HBifjW9Q5Hz',
          value: '1',
          label: 'Yes',
        },
        {
          id: '8kE6qD9SSMSjo7wpu',
          value: '2',
          label: 'No',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__RESIDENCY_LENGTH,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Residency Length',
      title: 'Length of residence in Singapore (months)',
    },
  },
  {
    _id: VARIABLE_ID__BIRTH_COUNTRY,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Country of Birth',
      title: 'What is your country of birth?',
    },
  },
  {
    _id: VARIABLE_ID__ADDRESS,
    scopeName: '@recipient',
    nativeKey: 'address.full',
    key: 'address.full',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Address',
      title: 'What is your address?',
    },
  },
  {
    // TODO: Turn this into array of strings ...
    _id: VARIABLE_ID__LANGUAGE,
    scopeName: '@recipient',
    nativeKey: 'languages.0.name',
    immutable: true, // accessor only
    key: 'languages.0.name',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Language',
      title: 'What languages do you speak?',
    },
  },
  {
    // TODO: This probably shouldn't be a variable at all.
    _id: VARIABLE_ID__AGE,
    scopeName: '@recipient',
    nativeKey: 'currentAge',
    key: 'currentAge',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Age',
      title: 'How old are you?',
    },
  },
  {
    _id: VARIABLE_ID__GENDER,
    variable: '@recipient.gender',
    scopeName: '@recipient',
    nativeKey: 'gender',
    jsonSchema: {
      type: 'string',
    },
    key: 'gender',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Gender',
      title: 'What is your gender?',
      choices: [
        {
          id: '46uYqxLxK2u7EQeg3',
          value: GENDER_MALE,
        },
        {
          id: '6ZWfQTqETGMCbbHqA',
          value: GENDER_FEMALE,
        },
        {
          id: 'NcuaZwtFuvy7xib3Q',
          value: GENDER_DIVERSE,
        },
        {
          id: 'SCtfScZd3y44GiDWq',
          value: GENDER_UNKNOWN,
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY,
    variable: '@recipient.ethnicity',
    scopeName: '@recipient',
    nativeKey: 'ethnicity',
    jsonSchema: {
      type: 'string',
    },
    key: 'ethnicity',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Ethnicity',
      title: 'What is your ethnicity?',
      settings: {
        verticalLayout: true,
      },
      choices: RECIPIENT_ETHNICITIES.map(ethnicity => ({
        value: ethnicity,
      })),
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY_PRE_C,
    variable: '@recipient.ethnicity',
    scopeName: '@recipient',
    nativeKey: 'ethnicity',
    jsonSchema: {
      type: 'string',
    },
    key: 'ethnicity',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Ethnicity',
      title: 'What is your ethnicity?',
      settings: {
        verticalLayout: true,
      },
      choices: RECIPIENTS_PRE_C_ETHNICITY.map(ethnicity => ({
        value: ethnicity,
      })),
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY_SINGAPORE,
    variable: '@recipient.ethnicity',
    scopeName: '@recipient',
    nativeKey: 'ethnicity',
    jsonSchema: {
      type: 'string',
    },
    key: 'ethnicity',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Ethnicity',
      title: 'What is your ethnicity?',
      settings: {
        verticalLayout: true,
      },
      choices: [
        {
          id: 'Xjq4atRebNtA5RoWM',
          value: 'Chinese',
        },
        {
          id: 'cGypSzN9fhoyjWLnZ',
          value: 'Malay',
        },
        {
          id: 'nEeHEoyky3HpFrave',
          value: 'Indian',
        },
        {
          id: 'qH4MQjvbtccR2GQiP',
          value: 'Others',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__RELIGION_OTHER,
    scopeName: '@recipient',
    nativeKey: 'religionOtherSpecified',
    key: 'religionOtherSpecified',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Other religion',
      title: 'Please specify your religion',
    },
  },
  {
    _id: VARIABLE_ID__FAMILY_HISTORY,
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Family History of psychiatric illness',
      title: 'Family History of psychiatric illness',
      choices: [
        {
          id: '1dczh2HBifjW9Q5Hz',
          value: '1',
          label: 'Yes',
        },
        {
          id: '2kE6qD9SSMSjo7wpu',
          value: '2',
          label: 'No',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__FAMILY_RELATIONSHIP,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Relation to patient',
      title: 'Relation to patient',
    },
  },
  {
    _id: VARIABLE_ID__FAMILY_DIAGNOSIS,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Diagnosis of relation',
      title: 'Diagnosis of relation',
    },
  },
  {
    _id: VARIABLE_ID__ADDRESS_STREET,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Street',
      title: 'Street',
    },
  },
  {
    _id: VARIABLE_ID__ADDRESS_SUBURB,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Suburb',
      title: 'Suburb',
    },
  },
  {
    _id: VARIABLE_ID__ADDRESS_CITY,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'City',
      title: 'City',
    },
  },
  {
    _id: VARIABLE_ID__ADDRESS_STATE,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'State',
      title: 'State',
    },
  },
  {
    _id: VARIABLE_ID__ADDRESS_CODE,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Postal / Zip Code',
      title: 'Postal / Zip Code',
    },
  },
  {
    _id: VARIABLE_ID__ADDRESS_COUNTRY,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Country',
      title: 'Country',
    },
  },
  {
    _id: VARIABLE_ID__TARGET_EDI,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Target EDI',
      title: 'Target EDI',
    },
  },
  {
    _id: VARIABLE_ID__SPECIALIST,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Specialist',
      title: 'Specialist',
    },
  },
  {
    _id: VARIABLE_ID__DISCHARGING_CLINICIAN,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Discharging Clinician',
      title: 'Discharging Clinician',
    },
  },
  {
    _id: VARIABLE_ID__ETHNICITY_OTHER,
    key: 'ethnicityOtherSpecified',
    scopeName: '@recipient',
    nativeKey: 'ethnicityOtherSpecified',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Other Ethnicity',
      title: 'Please specify your ethnicity',
    },
  },
  {
    _id: VARIABLE_ID__NATIONAL_ID,
    variable: '@recipient.nationalId',
    scopeName: '@recipient',
    nativeKey: 'nationalId',
    jsonSchema: {
      type: 'string',
    },
    key: 'nationalId',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'National Id',
      title: 'National Id',
    },
    fhir: {
      key: 'identifier',
      operator: 'push',
      value: {
        system: 'national_health_identifier',
        value: '$value',
      },
    },
  },
  {
    _id: VARIABLE_ID__ENCOUNTER_ID,
    variable: '@activity.encounterId',
    scopeName: '@activity',
    nativeKey: 'encounterId',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Encounter Id',
      title: 'Encounter Id',
    },
  },
  {
    _id: VARIABLE_ID__IDENTIFIER,
    variable: '@recipient.identifier',
    scopeName: '@recipient',
    nativeKey: 'identifier',
    jsonSchema: {
      type: 'string',
    },
    key: 'identifier',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Zedoc Identifier',
      title: 'What is your identifier?',
    },
  },
  {
    _id: VARIABLE_ID__CAREGIVER_NAME,
    scopeName: '@recipient',
    nativeKey: 'caregiver.name',
    key: 'caregiver.name',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Caregiver Name',
      title: 'What is your caregiver name?',
    },
  },
  {
    _id: VARIABLE_ID__CAREGIVER_PHONE,
    scopeName: '@recipient',
    nativeKey: 'caregiver.phone',
    key: 'caregiver.phone',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Caregiver Phone',
      title: 'What is your caregiver phone number?',
    },
  },
  {
    _id: VARIABLE_ID__CAREGIVER_EMAIL,
    scopeName: '@recipient',
    nativeKey: 'caregiver.email',
    key: 'caregiver.email',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Caregiver Email',
      title: 'What is your caregiver email address?',
    },
  },
  {
    _id: VARIABLE_ID__RECRUITER_NAME,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Recruiter Name',
      title: 'What is the name of the recruiter?',
    },
  },
  {
    _id: VARIABLE_ID__RECRUITMENT_DATE,
    question: {
      type: QUESTION_TYPE__DATE,
      label: 'Recruitment Date',
      title: 'What is the recruitment date?',
    },
  },
  {
    _id: VARIABLE_ID__INTERVIEWER_NAME,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Interviewer Name',
      title: 'What is the name of the interviewer?',
    },
  },
  {
    _id: VARIABLE_ID__INTERVIEW_DATE,
    question: {
      type: QUESTION_TYPE__DATE,
      label: 'Interview Date',
      title: 'What is the date of the interview?',
    },
  },
  {
    _id: VARIABLE_ID__ORGANIZATION_NAME,
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Organization Name',
      title:
        'What is the name of the organization for which this survey is performed?',
    },
  },
  {
    _id: VARIABLE_ID__DISCH_CLASS,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Disch. Class',
      title: 'Disch. Class',
    },
  },
  {
    _id: VARIABLE_ID__DISCH_DATE,
    question: {
      type: QUESTION_TYPE__DATE,
      label: 'Disch. Date',
      title: 'Disch. Date',
    },
  },
  {
    _id: VARIABLE_ID__DISCIPLINE,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Discipline',
      title: 'Discipline',
    },
  },
  {
    _id: VARIABLE_ID__WARD_AND_CLINIC,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Ward/Clinic',
      title: 'Ward/Clinic',
    },
  },
  {
    _id: VARIABLE_ID__WARD_CODE,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Ward Code',
      title: 'Ward Code',
    },
  },
  {
    _id: VARIABLE_ID__PHONE_H,
    scopeName: '@recipient',
    immutable: true,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Phone (Home)',
      title: 'Phone (Home)',
    },
    fhir: {
      key: 'telecom',
      operator: 'push',
      value: {
        use: 'home',
        system: 'phone',
        value: '$value',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONE_M,
    scopeName: '@recipient',
    immutable: true,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Phone (Mobile)',
      title: 'Phone (Mobile)',
    },
    fhir: {
      key: 'telecom',
      operator: 'push',
      value: {
        use: 'mobile',
        system: 'phone',
        value: '$value',
      },
    },
  },
  {
    _id: VARIABLE_ID__PHONE_W,
    scopeName: '@recipient',
    immutable: true,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Phone (Work)',
      title: 'Phone (Work)',
    },
    fhir: {
      key: 'telecom',
      operator: 'push',
      value: {
        use: 'work',
        system: 'phone',
        value: '$value',
      },
    },
  },
  {
    _id: VARIABLE_ID__ADM_VISIT_TIME,
    variable: `@activity.${VARIABLE_ID__ADM_VISIT_TIME}`,
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Adm/Visit. Time',
      title: 'Adm/Visit. Time',
    },
  },
  {
    _id: VARIABLE_ID__ADM_VISIT_DATE,
    variable: `@activity.${VARIABLE_ID__ADM_VISIT_DATE}`,
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__DATE,
      label: 'Adm/Visit. Date',
      title: 'Adm/Visit. Date',
    },
  },
  {
    _id: VARIABLE_ID__PACS,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'PACS',
      title: 'PACS',
    },
  },
  {
    _id: VARIABLE_ID__TRTOU,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'TrtOU',
      title: 'TrtOU',
    },
  },
  {
    _id: VARIABLE_ID__MARITAL_STATUS,
    scopeName: '@recipient',
    nativeKey: 'maritalStatus',
    key: 'maritalStatus',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Marital status',
      title: 'Marital status',
      choices: [
        {
          id: 'NAEqvAz37rWzeLLun',
          value: '1',
          label: 'Single / unmarried',
        },
        {
          id: 'gNQDhQXoj8EZCBYma',
          value: '2',
          label: 'Married',
        },
        {
          id: 'SsmxbX8gxJawCXHNz',
          value: '3',
          label: 'Separated',
        },
        {
          id: 'eSMaC62EYoGBBDAYY',
          value: '4',
          label: 'Divorced',
        },
        {
          id: 'CBkLbd4SCSvKmJ4o3',
          value: '5',
          label: 'Widowed',
        },
        {
          id: 'kMX3NoYmY3i5JMTpM',
          value: '9',
          label: 'Others',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__BMI,
    scopeName: '@recipient',
    nativeKey: 'BMI',
    key: 'BMI',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'BMI',
      title: 'BMI',
    },
  },
  {
    _id: VARIABLE_ID__RELIGION,
    scopeName: '@recipient',
    nativeKey: 'religion',
    key: 'religion',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Religion',
      title: 'Religion',
      choices: [
        {
          id: 'Z2yErA9uqzYc3HGdG',
          value: '1',
          label: 'Christianity',
        },
        {
          id: '4ev4BReHiGbFTKo27',
          value: '2',
          label: 'Buddhism',
        },
        {
          id: 'jLniqprFQtgeSP5Te',
          value: '3',
          label: 'Hinduism',
        },
        {
          id: 'cH3onaFSYHpp2GK2h',
          value: '4',
          label: 'Islam',
        },
        {
          id: '5uPGWNFaHmNTCuFSJ',
          value: '5',
          label: 'Taoism',
        },
        {
          id: 'nKX9sogWyvanFFoZh',
          value: '6',
          label: 'Catholicism',
        },
        {
          id: 'kPfmttcXH8ND5Xazv',
          value: '9',
          label: 'Others',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__RESIDENCY_STATUS,
    scopeName: '@recipient',
    nativeKey: 'residencyStatus',
    key: 'residencyStatus',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Residency status',
      title: 'Residency status',
      choices: [
        {
          id: 'cfNtPW84WzTdXhpBZ',
          value: '1',
          label: 'Singapore citizen',
        },
        {
          id: 'Dj4hRPd9iaAagMiep',
          value: '2',
          label: 'Permanent resident',
        },
        {
          id: 'rZb3MrH6hYvqYP6Hw',
          value: '3',
          label: 'Work permit',
        },
        {
          id: 'XgXKai2Ra3LqZ4S9u',
          value: '4',
          label: 'Student pass',
        },
        {
          id: 'M22ghTG7JZmkEgMi9',
          value: '5',
          label: 'Dependent pass',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__TOC_DESTINATION,
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Transfer of Care Destination',
      title: 'Transfer of Care Destination',
      choices: [
        {
          id: 'RCTjdfbSKWSxhZErE',
          value: '1',
          label: 'Com Psy or Gen Psy',
        },
        {
          id: 'y4hE6dfZRqDY4dgZy',
          value: '2',
          label: 'Follow-up with EPIP dr only',
        },
        {
          id: 'yBPoK5LsWu4ejnXFh',
          value: '3',
          label: 'GP-partnership',
        },
        {
          id: 'Tx6GuXxjeJTmWxCDb',
          value: '4',
          label: 'Defaulted and open TCU',
        },
        {
          id: 'NQGBLB6zneF4G6Bc5',
          value: '5',
          label: 'Extend EPIP service',
        },
        {
          id: 'g9R6DY94KbAvNC7Yv',
          value: '6',
          label: 'Open TCU',
        },
        {
          id: 'T3ixJDWE4GrRHgGkK',
          value: '9',
          label: 'Other',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__TOC_DESTINATION_OTHER,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Transfer of Care Destination Other',
      title: 'Please specify other transfer of care destination',
    },
  },
  {
    _id: VARIABLE_ID__TOC_DATE,
    question: {
      type: QUESTION_TYPE__DATE,
      label: 'Transfer of Care Date',
      title: 'Transfer of Care Date',
    },
  },
  {
    _id: VARIABLE_ID__EMPLOYMENT_TYPE,
    scopeName: '@recipient',
    nativeKey: 'employmentType',
    key: 'employmentType',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Employment',
      title: 'Employment',
      choices: [
        {
          id: 'bHGCqoC9Yari5hpgH',
          value: '1',
          label: 'Paid or self employment',
        },
        {
          id: '8D5eJYf3SLaALkCxE',
          value: '2',
          label: 'Sheltered employment',
        },
        {
          id: '8ayB8GTd7aP7wKACv',
          value: '3',
          label: 'Unemployed',
        },
        {
          id: 'rYRPsbBdyF3nuH5Cq',
          value: '4',
          label: 'Student',
        },
        {
          id: 'D5jg83JaCCPmXuuMr',
          value: '5',
          label: 'Homemaker',
        },
        {
          id: 'twdMPoo6NHAmbKS9b',
          value: '6',
          label: 'NSF',
        },
        {
          id: 'BNxCCJX8RkDiqZ6KT',
          value: '9',
          label: 'Others',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__INCOME_SOURCE,
    scopeName: '@recipient',
    nativeKey: 'incomeSource',
    key: 'incomeSource',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Income',
      title: 'Income',
      choices: [
        {
          id: '3dfxvjEXtRpNoePBW',
          value: '1',
          label: 'Salary / Wage',
        },
        {
          id: '636QuTkFS4fZoSKWd',
          value: '2',
          label: 'Public assistance',
        },
        {
          id: '4Tn5XuFcmFPMFw5zp',
          value: '3',
          label: 'Family',
        },
        {
          id: 'LsSWXNu252zvvkfAX',
          value: '4',
          label: 'Pension',
        },
        {
          id: 'Ho2R7v4z9zQg7nL4f',
          value: '5',
          label: 'Charitable organisation',
        },
        {
          id: 'YKhP2euzBRGjL54rE',
          value: '9',
          label: 'Others',
        },
      ],
    },
  },

  {
    _id: VARIABLE_ID__DEPARTMENT,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      title: 'Department',
      label: 'Department',
    },
  },
  {
    _id: VARIABLE_ID__SUB_GROUP,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      title: 'Sub Group',
      label: 'Sub Group',
    },
  },
  {
    _id: VARIABLE_ID__ORGANIZATION_UNIT,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      title: 'Organization Unit',
      label: 'Organization Unit',
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_1,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    patientServiceSafe: true,
    patientServiceId: 'bedNumber',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      title: 'Meta field 1',
      label: 'Meta field 1',
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_2,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    patientServiceSafe: true,
    patientServiceId: 'hall',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      title: 'Meta field 2',
      label: 'Meta field 2',
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_3,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'string',
    },
    patientServiceSafe: true,
    patientServiceId: 'category',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      title: 'Meta field 3',
      label: 'Meta field 3',
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_NUMBER_1,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'number',
    },
    patientServiceSafe: true,
    patientServiceId: 'row',
    question: {
      type: QUESTION_TYPE__NUMBER,
      title: 'Meta field number 1',
      label: 'Meta field number 1',
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_NUMBER_2,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'number',
    },
    patientServiceSafe: true,
    patientServiceId: 'bed',
    question: {
      type: QUESTION_TYPE__NUMBER,
      title: 'Meta field number 2',
      label: 'Meta field number 2',
    },
  },
  {
    _id: VARIABLE_ID__META_FIELD_NUMBER_3,
    scopeName: '@recipient',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      title: 'Meta field number 3',
      label: 'Meta field number 3',
    },
  },
  // Project-level variables
  {
    _id: VARIABLE_ID__STUDY_NO,
    variable: '@participation.studyNo',
    nativeKey: 'studyNo',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    key: 'participatedIn.studyNo',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Study No',
      title: 'Study No',
    },
  },
  {
    // NOTE: This is an evaluated value (function of studyNo)
    _id: VARIABLE_ID__STUDY_NO_TERMS,
    scopeName: '@participation',
    jsonSchema: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  {
    _id: VARIABLE_ID__ACCEPTED,
    variable: `@participation.${VARIABLE_ID__ACCEPTED}`,
    scopeName: '@participation',
    key: 'participatedIn.accepted',
    jsonSchema: {
      type: 'string',
    }, // Yes or No
    evaluated: true, // it's derived from participation.accepted
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Accepted',
      title: 'Accepted',
    },
  },
  {
    _id: VARIABLE_ID__DUP_ANTIPSYCHOTICS,
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'DUP (antipsychotics)',
      title: 'DUP (antipsychotics)',
      choices: [
        {
          id: 'wkvYJ4hdaJQh86r9i',
          value: '1',
          label: 'Yes',
        },
        {
          id: '2CTpdjENXdz3Z7FxA',
          value: '2',
          label: 'No',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__CONDITION,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Condition',
      title: 'Condition',
      placeholder: '[no information]',
    },
  },
  {
    _id: VARIABLE_ID__CHEMOTHERAPY_CYCLE_NO,
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Chemotherapy cycle number',
      title: 'Chemotherapy cycle number',
      placeholder: '[no information]',
    },
  },
  {
    _id: VARIABLE_ID__RADIOTHERAPY_WEEK_NUMBER,
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Radiotherapy week number',
      title: 'Radiotherapy week number',
      placeholder: '[no information]',
    },
  },
  {
    _id: VARIABLE_ID__LIST_OF_DIAGNOSES,
    question: {
      type: QUESTION_TYPE__SELECT_MANY,
      label: 'List of diagnoses',
      title: 'List of diagnoses',
      placeholder: '[no information]',
    },
  },
  {
    _id: VARIABLE_ID__PRIMARY_DIAGNOSIS,
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Primary diagnosis',
      title: 'Primary diagnosis',
      placeholder: '[no information]',
      choices: [
        {
          id: 'wjLNth28LFnWfyaFZ',
          value: '1',
          label: 'Schiz spectrum (Schiz, Schizophreniform & Schizoaffective)',
        },
        {
          id: 'pSbLw8sZ9c4MaNK4J',
          value: '2',
          label: 'Bipolar (with or without psychotic features)',
        },
        {
          id: '72x5WY8CGBRmoZR2k',
          value: '3',
          label: 'Delusional Disorder',
        },
        {
          id: 'KamiZRcphNpigFMe6',
          value: '4',
          label: 'Brief Psychotic Disorder',
        },
        {
          id: '5pp9BAv5suhNHnYyn',
          value: '5',
          label: 'Psychosis NOS',
        },
        {
          id: 'L2YqrcDPTNLxijtP2',
          value: '6',
          label: 'Depression (with psychotic features)',
        },
        {
          id: 'zs63c42o9SG9R6zDM',
          value: '7',
          label: 'At-Risk Mental State (ARMS)',
        },
        // { id: '25vAWjmAj34C8HQHn', value: '8', label: '' },
        {
          id: 'tJgP9hTW2xkodK4nX',
          value: '9',
          label: 'Others',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__SECONDARY_DIAGNOSIS,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Secondary diagnosis',
      title: 'Secondary diagnosis',
      placeholder: '[no information]',
    },
  },
  {
    _id: VARIABLE_ID__STATUS_IN_PROJECT,
    variable: '@participation.state',
    scopeName: '@participation',
    nativeKey: 'state',
    required: true,
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Status in project',
      title: 'Status in project',
    },
  },
  {
    _id: VARIABLE_ID__LENGTH_OF_STAY,
    variable: `@participation.${VARIABLE_ID__LENGTH_OF_STAY}`,
    scopeName: '@participation',
    key: 'participatedIn.lengthOfStay',
    jsonSchema: {
      type: 'number',
    },
    evaluated: true,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Length of stay until discharge',
      title: 'Length of stay unitl discharge',
    },
  },
  {
    _id: VARIABLE_ID__CLINICIAN,
    variable: '@participation.clinicianId',
    scopeName: '@participation',
    nativeKey: 'clinicianId',
    jsonSchema: {
      type: 'string',
    },
    key: 'participatedIn.clinicianId',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Clinician',
      title: 'Clinician',
    },
  },
  {
    _id: VARIABLE_ID__CASE_MANAGER,
    variable: '@participation.caseManagerId',
    scopeName: '@participation',
    nativeKey: 'caseManagerId',
    jsonSchema: {
      type: 'string',
    },
    key: 'participatedIn.caseManagerId',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Case Manager',
      title: 'Case Manager',
    },
  },
  {
    _id: VARIABLE_ID__CLINICIAN_NAME,
    variable: `@participation.${VARIABLE_ID__CLINICIAN_NAME}`,
    scopeName: '@participation',
    evaluated: true,
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Clinician Name',
      title: 'Clinician Name',
    },
  },
  {
    _id: VARIABLE_ID__CASE_MANAGER_NAME,
    variable: `@participation.${VARIABLE_ID__CASE_MANAGER_NAME}`,
    scopeName: '@participation',
    evaluated: true,
  },
  {
    _id: VARIABLE_ID__PHONE_SMS_NOTIFICATIONS,
    variable: '@recipient.smsNotificationNumber',
    scopeName: '@recipient',
    nativeKey: 'smsNotificationNumber',
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Phone (SMS notifications)',
      title: 'Phone (SMS notifications)',
    },
    fhir: {
      key: 'telecom',
      operator: 'push',
      value: {
        system: 'phone',
        value: '$value',
      },
    },
  },
  {
    // TODO: This should probably be called "BASELINE_YYMMDD"
    _id: VARIABLE_ID__PATIENT_BASELINE,
    scopeName: '@participation',
    nativeKey: 'baseline.yearMonthDay',
    key: 'participatedIn.baseline.yearMonthDay',
    question: {
      type: QUESTION_TYPE__DATE,
      label: 'Patient Baseline',
      title: 'Patient Baseline',
    },
  },
  {
    _id: VARIABLE_ID__CONSENT_OBTAINED,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Consent Obtained',
      title: 'Consent Obtained',
      choices: [
        {
          id: 'A4JeXhWsA6hmFvjjG',
          value: 'Yes',
        },
        {
          id: 'W8B4tWmTEPCqW7rMA',
          value: 'No',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__READMISSIONS,
    question: {
      type: QUESTION_TYPE__UNKNOWN,
      label: 'Re-admissions',
      title: 'Re-admissions',
      // NOTE: Similar functionality can now be implemented with collections.
      // properties: [
      //   { key: 'dateOfAdmission', label: 'Date of admission', type: QUESTION_TYPE__DATE },
      //   { key: 'dateOfDischarge', label: 'Date of discharge', type: QUESTION_TYPE__DATE },
      //   { key: 'lengthOfStay', label: 'Length of stay', type: QUESTION_TYPE__SHORT_TEXT },
      //   { key: 'reason', label: 'Reason', type: QUESTION_TYPE__SHORT_TEXT },
      //   { key: 'planned', label: 'Planned', type: QUESTION_TYPE__SHORT_TEXT },
      // ],
    },
  },
  {
    _id: VARIABLE_ID__NUMBER_OF_READMISSIONS,
    question: {
      label: '# Re-admissions',
      title: '# Re-admissions',
      type: QUESTION_TYPE__SHORT_TEXT,
    },
  },
  {
    _id: VARIABLE_ID__IMMUNOLOGICAL_STATUS,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Immunological status',
      title: 'Immunological status',
      choices: [
        {
          id: 'XgQi6DfGYimbuAWxD',
          value: 'RF and APCA negative',
        },
        {
          id: 'pMdNgPfzyNv5iYZBh',
          value: 'RF Positive',
        },
        {
          id: 'xRBgth39tLrGfDGd3',
          value: 'APCA Positive',
        },
        {
          id: '7vSRFeWYf8QmMABTJ',
          value: 'RF and APCA positive',
        },
      ],
    },
  },
  // Should be at the Recipient level but keeping it here to fit to existing UI.
  {
    _id: VARIABLE_ID__EDUCATION_LEVEL,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Education level',
      title: 'Education level',
      choices: [
        {
          id: 'dk5uiGSwvG3Xmzz9z',
          value: 'Primary',
        },
        {
          id: 'zkF8juSmgmE3xkEyy',
          value: 'Secondary',
        },
        {
          id: 'zdCZMtAN7e7Mtv7Nr',
          value: 'Tertiary',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__ALCOHOL_USE,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Alcohol Use',
      title: 'Alcohol Use',
      choices: [
        {
          id: 'P4qPsq3BiH4ZgR9ud',
          value: '>1 drink per day',
        },
        {
          id: 'oS9g7abPtDFbBKbZT',
          value: '<1 drink per day',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__HYPERTENSION_AND_DIABETES,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Hypertension and Diabetes',
      title: 'Hypertension and Diabetes',
      choices: [
        {
          id: 'BjZrDhqvf4HGAadHW',
          value: 'Hypertension',
        },
        {
          id: 'pZ579iNTwDWt9gJau',
          value: 'Diabetes',
        },
        {
          id: 'jA45PLHXhsmJC9wTh',
          value: 'Hypertension and diabetes',
        },
        {
          id: 'TMXYzRBCQjQ8o7DsD',
          value: 'None',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__PRIOR_ATRIAL_FIBRILLATION,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Prior Atrial fibrillation',
      title: 'Prior Atrial fibrillation',
      choices: [
        {
          id: 'riSZP7GjtrnFQZNkJ',
          value: 'Prior AF',
        },
        {
          id: '7Si38XrNu7xs7aBEq',
          value: 'No Prior AF',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__PRIOR_MYOCARDIAL_INFARCTION,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Prior Myocardial infarction',
      title: 'Prior Myocardial infarction',
      choices: [
        {
          id: 'TtnmbSA9EFmwcAyH7',
          value: 'Prior MI',
        },
        {
          id: 'FroeaAYc9rhZT2qAL',
          value: 'No Prior MI',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__RENAL_DYSFUNCTION,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Renal Dysfunction',
      title: 'Renal Dysfunction',
      choices: [
        {
          id: '2LqSvddnio44HvNwA',
          value: 'Renal Dysfunction',
        },
        {
          id: 'JuoSZFoJ25hv4xbLn',
          value: 'Healthy Renal Function',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__SMOKING_STATUS,
    scopeName: '@participation',
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Smoking status',
      title: 'Smoking status',
      choices: [
        {
          id: 'gj5J9ocdh7yEK8bmp',
          value: 'Never',
        },
        {
          id: 'nzbprNuAvYffixdr5',
          value: 'Current',
        },
        {
          id: 'txFKy8cHdBDvoiXgs',
          value: 'Former',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_BED_NUMBER,
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Expo: Bed number',
      title: 'Expo: Bed number',
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_CATEGORY,
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Expo: Category',
      title: 'Expo: Category',
      choices: [
        {
          value: 'Category 1',
        },
        {
          value: 'Category 2',
        },
        {
          value: 'Category 3',
        },
        {
          value: 'Category 4',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_EMR_SYNC,
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Expo: EMR Sync',
      title: 'Expo: EMR Sync',
      choices: [
        {
          value: 'Yes',
        },
        {
          value: 'No',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_HALL,
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Expo: Hall',
      title: 'Expo: Hall',
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_ROW,
    scopeName: '@participation',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: Row',
      title: 'Expo: Row',
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_BED,
    scopeName: '@participation',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: Bed',
      title: 'Expo: Bed',
    },
  },

  // PATIENT
  {
    _id: VARIABLE_ID__RECIPIENT_ID,
    variable: '@recipient.id',
    scopeName: '@recipient',
    nativeKey: '_id',
    immutable: true,
    required: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Recipient id',
      title: 'Recipient id',
    },
  },
  {
    _id: VARIABLE_ID__PRIMARY_IDENTIFIER,
    scopeName: '@recipient',
    nativeKey: 'identifiers.0.id',
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    patientServiceSafe: true,
    patientServiceId: 'identifier',
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Primary Identifier',
      title: 'Primary Identifier',
    },
  },

  // MESSAGE
  {
    _id: VARIABLE_ID__MESSAGE_TYPE,
    variable: '@message.type',
    scopeName: '@message',
    nativeKey: 'type',
    required: true,
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_ORIGINAL_TYPE,
    variable: '@message.originalType',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_ORIGINAL_ID,
    variable: '@message.originalId',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_PATIENT_ID,
    variable: '@message.patientId',
    scopeName: '@message',
    nativeKey: 'patientId',
    required: true,
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_PATIENT_ID_AUTHORITY,
    variable: '@message.patientIdAuthority',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_PROJECT_ID,
    variable: '@message.projectId',
    scopeName: '@message',
    nativeKey: 'projectId',
    required: true,
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_PROVIDER_ID,
    variable: '@message.providerId',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_PROVIDER_ID_AUTHORITY,
    variable: '@message.providerIdAuthority',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_PROVIDER_NAME,
    variable: '@message.providerName',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_PROCESSED_AT,
    variable: '@message.processedAt',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
      format: 'date-time', // tz is mandatory
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_SOURCE_FILE_NAME,
    variable: '@message.sourceFileName',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__MESSAGE_RECEIVED_AT,
    variable: '@message.receivedAt',
    scopeName: '@message',
    jsonSchema: {
      type: 'string',
      format: 'date-time', // tz is mandatory
    },
  },

  // ACTIVITY
  {
    _id: VARIABLE_ID__ACTIVITY_ID,
    scopeName: '@activity',
    nativeKey: '_id',
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      label: 'Activity id',
      title: 'Activity id',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_SCHEDULE,
    variable: '@activity.schedule',
    scopeName: '@activity',
    nativeKey: 'schedule',
    jsonSchema: {
      type: 'object',
      properties: {
        dateEvent: {
          type: 'object',
          properties: {
            yearMonthDay: {
              type: 'string',
              format: 'date',
            },
          },
        },
        dateStart: {
          type: 'object',
          properties: {
            yearMonthDay: {
              type: 'string',
              format: 'date',
            },
          },
        },
        dateEnd: {
          type: 'object',
          properties: {
            yearMonthDay: {
              type: 'string',
              format: 'date',
            },
          },
        },
      },
    },
  },
  // NOTE: These variables get replaced with:
  //       VARIABLE_ID__MILESTONE_ID
  //       VARIABLE_ID__MILESTONE_NAME
  {
    _id: VARIABLE_ID__ACTIVITY_MILESTONE_ID,
    variable: '@activity.milestoneId',
    scopeName: '@activity',
    nativeKey: 'milestoneId',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_MILESTONE_NAME,
    variable: '@activity.milestoneName',
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_CLINICIAN_ID,
    variable: '@activity.clinicianId',
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
    nativeKey: 'clinicianId',
  },
  {
    _id: VARIABLE_ID__ACTIVITY_CASE_MANAGER_ID,
    variable: '@activity.caseManagerId',
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
    nativeKey: 'caseManagerId',
  },
  {
    _id: VARIABLE_ID__ACTIVITY_CLINICIAN_NAME,
    variable: '@activity.clinicianName',
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    // e.g. outpatient/ inpatient
    _id: VARIABLE_ID__ACTIVITY_PATIENT_TYPE,
    variable: '@activity.patientType',
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_TEMP,
    scopeName: '@activity',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: Temp',
      title: 'Expo: Temp',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_SPO2,
    scopeName: '@activity',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: SPO2',
      title: 'Expo: SPO2',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_SYSTOLIC_BP,
    scopeName: '@activity',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: Systolic BP',
      title: 'Expo: Systolic BP',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_DIASTOLIC_BP,
    scopeName: '@activity',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: Diastolic BP',
      title: 'Expo: Diastolic BP',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_HR,
    scopeName: '@activity',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: HR',
      title: 'Expo: HR',
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_DYSPNOEA,
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Expo: Dyspnoea',
      title: 'Expo: Dyspnoea',
      choices: [
        {
          value: 'Yes',
        },
        {
          value: 'No',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_MEDS,
    scopeName: '@activity',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SELECT_ONE,
      label: 'Expo: Meds',
      title: 'Expo: Meds',
      choices: [
        {
          value: 'Yes',
        },
        {
          value: 'No',
        },
      ],
    },
  },
  {
    _id: VARIABLE_ID__ACTIVITY_SI,
    scopeName: '@activity',
    jsonSchema: {
      type: 'number',
    },
    question: {
      type: QUESTION_TYPE__NUMBER,
      label: 'Expo: SI',
      title: 'Expo: SI',
    },
  },

  // ANSWERS_SHEET
  {
    _id: VARIABLE_ID__ANSWERS_SHEET_ID,
    scopeName: '@answersSheet',
    nativeKey: '_id',
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      label: 'Answer sheet id',
      title: 'Answer sheet id',
    },
  },
  {
    _id: VARIABLE_ID__ANSWERS_SHEET_USER_AGENT_DEVICE,
    scopeName: '@answersSheet',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__ANSWERS_SHEET_USER_AGENT_OS,
    scopeName: '@answersSheet',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__ANSWERS_SHEET_USER_AGENT_BROWSER,
    scopeName: '@answersSheet',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__ANSWERS_SHEET_SECONDS_TO_COMPLETE,
    scopeName: '@answersSheet',
    jsonSchema: {
      type: 'number',
    },
  },
  {
    _id: VARIABLE_ID__ANSWERS_SHEET_TIMEZONE,
    scopeName: '@answersSheet',
    nativeKey: 'timezone',
    jsonSchema: {
      type: 'string',
    },
  },
  {
    _id: VARIABLE_ID__ANSWERS_SHEET_LANGUAGE,
    scopeName: '@answersSheet',
    nativeKey: 'language',
    jsonSchema: {
      type: 'string',
    },
  },

  // PARTICIPATION
  {
    _id: VARIABLE_ID__PARTICIPATION_ID,
    scopeName: '@participation',
    nativeKey: '_id',
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      label: 'Prticipation id (random)',
      title: 'Prticipation id (random)',
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_TRACK_ID,
    scopeName: '@participation',
    nativeKey: 'trackId',
    jsonSchema: {
      type: 'string',
    },
    question: {
      label: 'Prticipation track id',
      title: 'Prticipation track id',
    },
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_SEQUENCE_NO,
    variable: '@participation.sequenceNo',
    scopeName: '@participation',
    jsonSchema: {
      type: 'number',
    },
    nativeKey: 'sequenceNo',
    immutable: true,
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_PROJECT_ID,
    variable: '@participation.projectId',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    nativeKey: 'projectId',
    immutable: true,
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_PROJECT_NAME,
    variable: '@participation.projectName',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    immutable: true,
  },
  {
    _id: VARIABLE_ID__PARTICIPATION_SUBSPECIALTY,
    variable: '@participation.subspecialty',
    scopeName: '@participation',
    jsonSchema: {
      type: 'string',
    },
    immutable: true,
  },

  // PROJECT
  {
    _id: VARIABLE_ID__PROJECT_ID,
    scopeName: '@project',
    nativeKey: '_id',
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Project id',
      title: 'Project id',
    },
  },
  {
    _id: VARIABLE_ID__PROJECT_NAME,
    scopeName: '@project',
    nativeKey: 'name',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Project name',
      title: 'Project name',
    },
  },

  // MILESTONE
  {
    _id: VARIABLE_ID__MILESTONE_ID,
    scopeName: '@milestone',
    nativeKey: '_id',
    immutable: true,
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Milestone id',
      title: 'Milestone id',
    },
  },
  {
    _id: VARIABLE_ID__MILESTONE_NAME,
    scopeName: '@milestone',
    nativeKey: 'name',
    jsonSchema: {
      type: 'string',
    },
    question: {
      type: QUESTION_TYPE__SHORT_TEXT,
      label: 'Milestone name',
      title: 'Milestone name',
    },
  },
];

export const VARIABLES = {};
BUILT_IN_VARIABLES.forEach((variable) => {
  VARIABLES[variable._id] = variable;
});

export const variablesById = VARIABLES;
export const variablesByScope = {};
BUILT_IN_VARIABLES.forEach((variable) => {
  if (variable.scopeName) {
    if (!variablesByScope[variable.scopeName]) {
      variablesByScope[variable.scopeName] = {};
    }
    variablesByScope[variable.scopeName][variable._id] = variable;
  }
});
